import { CallMade, Mic } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost, isProductionStage } from '@spinach-shared/utils';

import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import { ReactComponent as AccountIcon } from '../../../../assets/sidebar/account_icon.svg';
import { ReactComponent as AdminIcon } from '../../../../assets/sidebar/admin_icon.svg';
import { ReactComponent as AgentIcon } from '../../../../assets/sidebar/agent_icon.svg';
import { ReactComponent as AskSpinachIcon } from '../../../../assets/sidebar/ask-spinach_icon.svg';
import { ReactComponent as BackOfficeIcon } from '../../../../assets/sidebar/back-office_icon.svg';
import { ReactComponent as ControllerIcon } from '../../../../assets/sidebar/controller_icon.svg';
import { ReactComponent as DraftsIcon } from '../../../../assets/sidebar/drafts_icon.svg';
import { ReactComponent as FutureMeetingIcon } from '../../../../assets/sidebar/future-meeting_icon.svg';
import { ReactComponent as HelpCenterIcon } from '../../../../assets/sidebar/help-center_icon.svg';
import { ReactComponent as MeetingHistoryIcon } from '../../../../assets/sidebar/meeting-history_icon.svg';
import { ReactComponent as ReferralIcon } from '../../../../assets/sidebar/referral_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/sidebar/settings_icon.svg';
import { ReactComponent as TutorialIcon } from '../../../../assets/sidebar/tutorial_icon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/sidebar/upload_icon.svg';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalRouting,
    useSidebar,
    useStripeUpgradeInAppEnablement,
} from '../../../../hooks';
import { useGlobalDrafts } from '../../../../hooks/useGlobalDrafts';
import { lightTheme } from '../../../../styles';
import { URLUtil, isWebPlatform } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { BetaFeatureTag, ClientPath, Column, Hairline, Row, Spacing } from '../../../common';
import { SidebarRecorder } from '../recording/SidebarRecorder';
import { isSummariesAndTranscriptsSection } from '../utils';
import { ProfileComponent } from './NavBar';
import { NavBarButton } from './NavBarButton';
import { SearchBox } from './SearchBox';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function FullFeaturedSidebarContent(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [drafts] = useGlobalDrafts();
    const track = useExperienceTracking();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const { routeToControl } = useGlobalRouting();
    const { isDesktopView } = useSidebar();
    const { activeSection, setActiveSection } = useGlobalAiDashboard();

    return (
        <SidebarColumn>
            {isDesktopView ? (
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '35px' : '25px',
                        minHeight: isDesktopView ? '35px' : '25px',
                        cursor: 'pointer',
                        marginLeft: '-35px',
                        marginBottom: '20px',
                        marginTop: '5px',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                        setActiveSection(DashboardSection.Summaries);
                    }}
                />
            ) : null}

            {isDesktopView ? (
                <>
                    <ProfileComponent />
                    <Spacing factor={1 / 2} />
                </>
            ) : null}

            {isDesktopView ? <SearchBox /> : null}

            {user.isEnabledForSidebarRecorder && isWebPlatform() && isDesktopView && !user.isAnonymous ? (
                <>
                    <SidebarRecorder />
                    <Spacing factor={1 / 2} />
                </>
            ) : null}

            {!isDesktopView && user.isEnabledForSidebarRecorderOnMobile && user.isEnabledForSidebarRecorder && isWebPlatform() && !user.isAnonymous ? (
                <>
                    <NavBarButton
                        title="Quick Record"
                        startIcon={<Mic style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        section={DashboardSection.QuickRecord}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Quick Record Tab' });
                            setActiveSection(DashboardSection.QuickRecord);
                        }}
                    />
                    <Hairline style={{ width: '70%' }} />
                </>
            ) : null}

            <NavBarButton
                title="Meeting History"
                startIcon={<MeetingHistoryIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                section={DashboardSection.Summaries}
                activeSection={isSummariesAndTranscriptsSection(activeSection) ? DashboardSection.Summaries : undefined}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                    setActiveSection(DashboardSection.Summaries);
                }}
            />
            <NavBarButton
                title="Future Meetings"
                startIcon={<FutureMeetingIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                section={DashboardSection.Meetings}
                activeSection={activeSection}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                    setActiveSection(DashboardSection.Meetings);
                }}
            />

            {user.isAskSpinachPageEnabled ? (
                <>
                    <NavBarButton
                        title="Ask Spinach"
                        startIcon={<AskSpinachIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        section={DashboardSection.AskSpinachPage}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Ask Spinach Page' });
                            setActiveSection(DashboardSection.AskSpinachPage);
                        }}
                    />
                </>
            ) : null}

            {user.isEnabledForAgentsSection ? (
                <>
                    <NavBarButton
                        startIcon={<AgentIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title={
                            <Row vCenter>
                                Agents{' '}
                                <span style={{ marginLeft: '5px' }}>
                                    <BetaFeatureTag />
                                </span>
                            </Row>
                        }
                        section={DashboardSection.Agents}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Agents Tab' });
                            setActiveSection(DashboardSection.Agents);
                        }}
                    />
                </>
            ) : null}

            {user.isEnabledForDraftsSection ? (
                <>
                    <NavBarButton
                        startIcon={<DraftsIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title={
                            <Row vCenter>
                                Drafts{' '}
                                {!!drafts?.length ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginLeft: '6px',
                                            color: 'white',
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '50%',
                                            fontSize:
                                                drafts.length < 10 ? '16px' : drafts.length < 100 ? '14px' : '10px',
                                            backgroundColor: lightTheme.primary.greenLight,
                                        }}
                                    >
                                        {drafts.length > 99 ? '99+' : drafts.length}
                                    </div>
                                ) : null}
                            </Row>
                        }
                        section={DashboardSection.Drafts}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Drafts Tab',
                                NumberOfDrafts: drafts?.length,
                            });
                            const newSearchParams = new URLSearchParams(location.search);
                            newSearchParams.delete(WebUrlQuery.SeriesId);
                            newSearchParams.delete(WebUrlQuery.BotId);
                            newSearchParams.set(WebUrlQuery.Section, DashboardSection.Drafts);
                            setActiveSection(DashboardSection.Drafts, {}, newSearchParams);
                        }}
                    />
                </>
            ) : null}
            {user.isEnabledForFileUpload ? (
                <NavBarButton
                    startIcon={<UploadIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                    title="Upload"
                    section={DashboardSection.Upload}
                    activeSection={activeSection}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Upload Tab' });
                        setActiveSection(DashboardSection.Upload);
                    }}
                />
            ) : null}
            {user.isEnabledForPlanner ? (
                <NavBarButton
                    startIcon={<SettingsIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                    title="Planner"
                    section={DashboardSection.Planner}
                    activeSection={activeSection}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Planner Tab' });
                        setActiveSection(DashboardSection.Planner);
                    }}
                />
            ) : null}
            {user.isEnabledForControlInNavBar ? (
                <>
                    <NavBarButton
                        startIcon={<ControllerIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Agenda Controls"
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Open Agenda Controls from Navbar',
                            });
                            if (isWebPlatform()) {
                                URLUtil.openURLPopUp(`${getWebAppHost()}${ClientPath.Control}`);
                            } else {
                                routeToControl();
                            }
                        }}
                        endIcon={
                            isWebPlatform() ? (
                                <CallMade style={{ fontSize: '16px', marginLeft: '5px' }} htmlColor={'#0000009c'} />
                            ) : undefined
                        }
                    />
                </>
            ) : null}

            <Hairline style={{ width: '70%' }} />
            <NavBarButton
                startIcon={<SettingsIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                title="Settings"
                section={DashboardSection.Integrations}
                activeSection={activeSection}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                    setActiveSection(DashboardSection.Integrations);
                }}
            />
            {user.isEnabledForEmbeddedWebflow ? (
                <>
                    <NavBarButton
                        startIcon={<TutorialIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title={user.embeddedWebflowCopy}
                        section={DashboardSection.Guides}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Embedded Webflow Tab' });
                            setActiveSection(DashboardSection.Guides);
                        }}
                    />
                </>
            ) : null}
            {isStripeUpgradeInAppEnabled ? (
                <>
                    <NavBarButton
                        startIcon={<AccountIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Account"
                        section={DashboardSection.Account}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Account Tab' });
                            setActiveSection(DashboardSection.Account);
                        }}
                    />
                </>
            ) : null}

            {user.hasAdminSectionAccess ? (
                <>
                    <NavBarButton
                        startIcon={<AdminIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Admin"
                        section={DashboardSection.Admin}
                        activeSection={activeSection}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Admin Tab' });
                            setActiveSection(DashboardSection.Admin);
                        }}
                    />
                </>
            ) : null}

            {isProductionStage() ? null : (
                <>
                    <NavBarButton
                        startIcon={<BackOfficeIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                        title="Backoffice"
                        section={DashboardSection.Backoffice}
                        activeSection={activeSection}
                        onClick={() => {
                            setActiveSection(DashboardSection.Backoffice);
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Backoffice Tab' });
                        }}
                    />
                </>
            )}

            {user.isEnabledForReferralInSidebar ? (
                <NavBarButton
                    id="cello-custom-referral-button"
                    startIcon={<ReferralIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                    title="Referrals"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Referrals Sidebar Button' });
                    }}
                />
            ) : null}

            <NavBarButton
                startIcon={<HelpCenterIcon style={{ marginRight: '10px', width: '18px', height: '18px' }} />}
                title="Help Center"
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                    URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL'));
                }}
                endIcon={<CallMade style={{ fontSize: '16px', marginLeft: '5px' }} htmlColor={'#0000009c'} />}
            />
        </SidebarColumn>
    );
}
