import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { NavigateOptions, Route, Routes, To, useLocation, useSearchParams } from 'react-router-dom';

import { DashboardSection, demoBotId, demoSeriesId } from '@spinach-shared/constants';
import { ClientEventType, FeatureToggle, UTMSource, UserCreationSource, WebUrlQuery } from '@spinach-shared/types';
import { KnownCookie } from '@spinach-shared/utils';
import { isProductionStage, isTicketSource } from '@spinach-shared/utils';
import { getSummaryDeepLink } from '@spinach-shared/utils';

import { patchUser } from '../../apis';
import { deleteGoogleCalendarSettings } from '../../apis/deleteGoogleCalendarSettings';
import { GlobalModal, GlobalModalMetadataType } from '../../atoms';
import {
    useEmailSearchRemoval,
    useExperienceTracking,
    useExtensionAvatar,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNavDrawer,
    useGlobalRouting,
    useLocationalSeriesId,
    useLoggedOutDetection,
    useNoteViewerDetection,
    usePreventBackspace,
    useTagManagerAuthedTracking,
    useUserIdentification,
} from '../../hooks';
import { useActivateTrialReturningUser } from '../../hooks/useActivateTrialReturningUser';
import { useCustomUserBrandedImage } from '../../hooks/useGlobalBrandedImage';
import { useFetchGlobalCustomTemplates } from '../../hooks/useGlobalCustomTemplates';
import { useFetchStoredSeriesList, useStoredSeriesListFetcher } from '../../hooks/useGlobalStoredSeriesList';
import { useHandleSpinachToggleFromUrl } from '../../hooks/useHandleSpinachToggleFromUrl';
import { useSetupAutoRecordUponOnboarding } from '../../hooks/useSetupAutoRecordUponOnboarding';
import { useSlackInstallLink } from '../../hooks/useSlack';
import { useStartupProcesses } from '../../hooks/useStartupProcesses';
import { useZoomCollabRedirect } from '../../hooks/useZoomCollabRedirect';
import { getCookieValue, isChromeExtensionPlatform, isWebPlatform, isZoomPlatform } from '../../utils';
import { getClientConfigValue } from '../../utils/getClientConfigValue';
import { WebAuthRouter } from '../auth';
import { VerifyGoogleCode } from '../auth/VerifyGoogleCode';
import { VerifyGoogleOneTap } from '../auth/VerifyGoogleOneTap';
import { VerifyMicrosoftCode } from '../auth/VerifyMicrosoftCode';
import { ControlPage } from '../control/VideoAgentControlPage';
import { TrySpinachContainer } from '../demo';
import { OnboardingRouter } from '../onboarding';
import { FirstSeriesFlowContainer, SeriesDashboard } from '../series';
import { AIEditSummary } from '../spinach-ai/AIEditSummary';
import { AIDashboard, StandupAppDashboard } from '../spinach-ai/dashboard';
import { FacilitationContainer } from '../spinach-ai/facilitation';
import { AiOnboardingWithExperimentation } from '../spinach-ai/onboarding/AiOnboardingWithExperimentation';
import { ClientPath } from './ClientPaths';
import { FYI, FYIState } from './FYI';
import { GlobalModalContainer } from './GlobalModalContainer';
import { SpinachMeetingValidator } from './SpinachMeetingValidator';
import { VideoAgent } from './agent-video/VideoAgent';
import { SSOProvisioningInstructionsModal } from './modals/SSOProvisioningInstructions';

function shouldMigrateToNewDomain(): boolean {
    return getClientConfigValue('REACT_APP_MIGRATE_TO_NEW_DOMAIN') === 'true' && isWebPlatform();
}

function isLegacyDomain(): boolean {
    return window.location.hostname.endsWith('.io');
}

function isKnownCookie(key: string): key is KnownCookie {
    return Object.values(KnownCookie).includes(key as KnownCookie);
}

function sanitizeCookieValue(value: string): string {
    // Encode special characters to prevent XSS
    return encodeURIComponent(value);
}

function isValidStorageData(data: unknown): data is Record<string, string> {
    return (
        typeof data === 'object' &&
        data !== null &&
        Object.entries(data).every(([key, value]) => typeof key === 'string' && typeof value === 'string')
    );
}

function migrateToNewDomain() {
    // Handle cookies
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [key, value] = cookie.trim().split('=');

        if (isKnownCookie(key)) {
            acc[key] = sanitizeCookieValue(value);
        }
        return acc;
    }, {} as Record<string, string>);

    const cookieState = btoa(JSON.stringify(cookies));

    const newDomain = window.location.hostname.replace('.io', '.ai');
    const newUrl = new URL(window.location.href);
    newUrl.hostname = newDomain;
    newUrl.hash = `${WebUrlQuery.CookieState}=${cookieState}`;
    window.location.href = newUrl.toString();
}

function restoreCookies(navigateTo: (to: To, options?: NavigateOptions) => void) {
    const url = new URL(window.location.href);
    const hashParams = new URLSearchParams(url.hash.slice(1));
    const cookieState = hashParams.get(WebUrlQuery.CookieState);

    let success = true;

    if (cookieState) {
        try {
            const parsedCookies = JSON.parse(atob(cookieState));
            if (isValidStorageData(parsedCookies)) {
                Object.entries(parsedCookies).forEach(([key, value]) => {
                    if (isKnownCookie(key)) {
                        const existingCookie = getCookieValue(key);
                        if (existingCookie) {
                            return;
                        }
                        const decodedValue = decodeURIComponent(value);
                        document.cookie = `${key}=${decodedValue}; path=/; domain=${window.location.hostname}`;
                    }
                });
            }
        } catch (error) {
            Sentry.captureException(error);
            success = false;
        }
    }

    if (success) {
        url.hash = '';
        navigateTo({ pathname: url.pathname, search: url.search }, { replace: true });
    }
}

export function useInitialAuthRouting() {
    const location = useLocation();
    const [user, setUser] = useGlobalAuthedUser();
    const {
        routeToOnboarding,
        routeToDirectExperience,
        routeToSeriesExperience,
        routeToExperiment,
        routeToScribeOnboarding,
        navigateToRedirect,
        routeToAIDashboard,
        routeToControl,
    } = useGlobalRouting();
    const { openSummaries } = useGlobalNavDrawer();
    const [, setModal] = useGlobalModal();
    const seriesId = useLocationalSeriesId();
    const [params] = useSearchParams();
    useHandleSpinachToggleFromUrl();
    const track = useExperienceTracking();
    const experimentCode = params.get(WebUrlQuery.Experiment);
    const fromSlack = params.get(WebUrlQuery.UTMSource) && params.get(WebUrlQuery.UTMSource) === UTMSource.Slack;
    const actionItemId = params.get(WebUrlQuery.ActionItemId);
    const ticketIntegrationSource = params.get(WebUrlQuery.TicketSource);
    const encodedRedirectTo = params.get(WebUrlQuery.Redirect);
    const botId = params.get(WebUrlQuery.BotId);
    const sid = params.get(WebUrlQuery.SeriesId);
    const redirectToPath = decodeURIComponent(encodedRedirectTo ?? '');
    const section = params.get(WebUrlQuery.Section);
    const testOnboardingFlow = params.get(WebUrlQuery.TestOnboardingFlow)?.toLocaleLowerCase();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const isViewingNotes = useNoteViewerDetection();

    function testOnboarding() {
        if (testOnboardingFlow === 'legacy') {
            patchUser({ metadata: { isOnboarded: false } });
            deleteGoogleCalendarSettings()
                .then((user) => {
                    if (user) {
                        setUser(user);
                    }
                    params.delete(WebUrlQuery.TestOnboardingFlow);
                    routeToOnboarding({ replace: true }, params);
                })
                .catch((error) => {
                    console.error(error);
                });
            return true;
        } else if (testOnboardingFlow === 'ai') {
            patchUser({ metadata: { isOnboarded: false } });
            deleteGoogleCalendarSettings()
                .then((user) => {
                    if (user) {
                        setUser(user);
                    }
                    params.delete(WebUrlQuery.TestOnboardingFlow);
                    routeToScribeOnboarding({ replace: true }, params);
                })
                .catch((error) => {
                    console.error(error);
                });
            return true;
        } else if (testOnboardingFlow === 'v1') {
            patchUser({ metadata: { isOnboarded: false, creationSource: UserCreationSource.GoogleSignInV2 } })
                .then((res) => {
                    if (res.user) {
                        setUser(res.user);
                    }
                    params.delete(WebUrlQuery.TestOnboardingFlow);
                    routeToScribeOnboarding({ replace: true }, params);
                })
                .catch((error) => {
                    console.error(error);
                });
            return true;
        }
    }

    useEffect(() => {
        async function handleStartupLogic() {
            if (!isProductionStage() && testOnboardingFlow) {
                const isTesting = testOnboarding();
                if (isTesting) {
                    return;
                }
            }

            if (user.isAnonymous) {
                return;
            }

            if (isViewingNotes && !user.isOnboarded) {
                routeToAIDashboard({ replace: true }, params);
                return;
            }

            if (user.isEnabledForForceAiOnboardingForOldUsers && !user.isAuthedForAnyCalendar && !user.isAnonymous) {
                routeToScribeOnboarding({ replace: true });
                return;
            }

            if (
                user.isEnabledForAutoOpenAgentControlOnEmbeddedApps &&
                (isZoomPlatform() || isChromeExtensionPlatform())
            ) {
                routeToControl({ replace: true });
                return;
            }

            // this must precede any skip-onboarding flows below
            if (!user.metadata.isOnboarded && user.isForcedLegacyOnboarding) {
                routeToOnboarding({ replace: true });
                return;
            }

            if (location.pathname === ClientPath.Sample) {
                window.location.href = getSummaryDeepLink({ botId: demoBotId, seriesId: demoSeriesId });
                return;
            }

            if (redirectToPath === ClientPath.AIHome && section === DashboardSection.Account) {
                patchUser({ metadata: { isOnboarded: true } });
                navigateToRedirect(redirectToPath);
                return;
            }

            if (
                redirectToPath === ClientPath.AIHome &&
                ticketIntegrationSource &&
                user.isActionItemTicketCreationEnabled
            ) {
                if (!user.metadata.isOnboarded) {
                    patchUser({ metadata: { isOnboarded: true } });
                }

                track(ClientEventType.UserRedirectedFromAiEmail, {
                    TicketSource: ticketIntegrationSource,
                    BotId: botId,
                    Source: params.get(WebUrlQuery.Source),
                    Campaign: params.get(WebUrlQuery.Campaign),
                    RedirectTo: 'ActionsSection',
                    ShouldAuthUser:
                        !isTicketSource(ticketIntegrationSource) ||
                        (!user.isAuthedForTicketSource(ticketIntegrationSource) &&
                            user.actionItemCreationEnabledTicketSources.includes(ticketIntegrationSource)),
                    ActionItemId: actionItemId,
                });
                navigateToRedirect(redirectToPath);

                /** @NOTE automatically open auth for team's integration source if user is not authed */
                if (
                    isTicketSource(ticketIntegrationSource) &&
                    user.actionItemCreationEnabledTicketSources.includes(ticketIntegrationSource) &&
                    !user.isAuthedForTicketSource(ticketIntegrationSource)
                ) {
                    setTimeout(() => {
                        setModal(GlobalModal.ConnectTicketSource, {
                            metadata: {
                                metadataType: GlobalModalMetadataType.TicketIntegration,
                                botId,
                                seriesId: sid,
                                teamTicketIntegration: ticketIntegrationSource,
                            },
                        });
                    }, 250);
                }
                return;
            }

            // Ensure the Control path is not redirected
            if (location.pathname === ClientPath.Control) {
                return;
            }

            // this is below the redirects above because theyre intentionally skipping onboarding for users
            if (!user.metadata.isOnboarded) {
                routeToScribeOnboarding({ replace: true });
                return;
            }

            if (user.isDemoing) {
                return;
            }
            if (
                location.pathname.match(
                    new RegExp(ClientPath.MeetingPath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
                ) &&
                fromSlack
            ) {
                track(ClientEventType.UserRedirectedFromSlackUrl, {
                    UTMSource: params.get(WebUrlQuery.UTMSource),
                    UTMMedium: params.get(WebUrlQuery.UTMMedium),
                    UTMCampaign: params.get(WebUrlQuery.UTMCampaign),
                });
            }

            if (experimentCode) {
                routeToExperiment();
                return;
            }

            if (redirectToPath) {
                navigateToRedirect(redirectToPath);
                return;
            } else if (location.pathname === ClientPath.AIHome || location.pathname === ClientPath.AIEditSummary) {
                return;
            } else if (location.pathname === ClientPath.Onboarding && user.metadata.isOnboarded) {
                routeToDirectExperience({ replace: true });
            } else if (
                location.pathname.match(
                    new RegExp(ClientPath.SummariesPath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
                )
            ) {
                openSummaries();
                routeToSeriesExperience(seriesId, { replace: true });
            } else if (
                location.pathname.match(
                    new RegExp(ClientPath.SchedulePath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
                )
            ) {
                setModal(GlobalModal.Schedule);
                routeToSeriesExperience(seriesId, { replace: true });
            } else if (
                location.pathname.match(
                    new RegExp(ClientPath.CustomizeSeriesPath.replace(ClientPath.SeriesIdParam, 'SPS[a-z0-9]{10}'))
                )
            ) {
                setModal(GlobalModal.SeriesSettings);
                routeToSeriesExperience(seriesId, { replace: true });
            } else if (location.pathname !== ClientPath.Demo) {
                routeToDirectExperience({ replace: true });
            }
        }

        handleStartupLogic();
    }, []);
}

export function AuthedUserRouter(): JSX.Element {
    const [user] = useGlobalAuthedUser();

    useFetchStoredSeriesList({
        recurringOnly: false,
    });
    useInitialAuthRouting();
    useUserIdentification(user);
    useEmailSearchRemoval();
    usePreventBackspace();
    useZoomCollabRedirect();
    useSlackInstallLink();
    useCustomUserBrandedImage();
    useTagManagerAuthedTracking();
    useExtensionAvatar();
    useActivateTrialReturningUser();
    useSetupAutoRecordUponOnboarding();
    useFetchGlobalCustomTemplates();

    return (
        <>
            <Routes>
                <Route
                    path={ClientPath.Root}
                    element={
                        user.featureToggles[FeatureToggle.NewStandupAppDashboard] ? (
                            <StandupAppDashboard />
                        ) : (
                            <SeriesDashboard />
                        )
                    }
                />
                <Route path={ClientPath.OnboardingAI} element={<AiOnboardingWithExperimentation />} />,
                <Route path={ClientPath.AIHome} element={<AIDashboard />} />,
                <Route path={ClientPath.AIEditSummary} element={<AIEditSummary />} />,
                <Route path={ClientPath.AIFacilitation} element={<FacilitationContainer />} />,
                <Route path={ClientPath.Onboarding} element={<OnboardingRouter />} />
                <Route path={ClientPath.CreateSeriesFlow} element={<FirstSeriesFlowContainer />} />
                <Route path={ClientPath.Demo} element={<SpinachMeetingValidator />} />
                <Route path={ClientPath.Meeting}>
                    <Route path={ClientPath.SeriesIdParam} element={<SpinachMeetingValidator />} />
                </Route>
                <Route path={ClientPath.Control} element={<ControlPage />} />
                <Route path={ClientPath.ControlWithBot} element={<ControlPage />} />
                <Route path={ClientPath.Unknown} element={<SeriesDashboard />} />
                <Route path={ClientPath.TrySpinach} element={<TrySpinachContainer />} />
                <Route path={ClientPath.Redirect} element={<FYI state={FYIState.Loading} />} />
            </Routes>
            <GlobalModalContainer />
        </>
    );
}

export function SpinachStartup(): JSX.Element {
    const { navigateTo } = useGlobalRouting();
    useEffect(() => {
        if (shouldMigrateToNewDomain()) {
            if (isLegacyDomain()) {
                migrateToNewDomain();
                return;
            }
            restoreCookies(navigateTo);
        }
    }, []);

    const shouldMigrateUserToNewDomain = shouldMigrateToNewDomain() && isLegacyDomain();
    const { pathname } = useLocation();
    if (shouldMigrateUserToNewDomain) {
        return <></>;
    }
    switch (pathname) {
        case ClientPath.VideoAgent:
            return <VideoAgent />;
        default:
            return <SpinachAppStartup />;
    }
}

export function SpinachAppStartup(): JSX.Element {
    const { user, isLoading } = useStartupProcesses();
    const location = useLocation();
    useLoggedOutDetection();

    if (isLoading || user === undefined) {
        return <FYI state={FYIState.Loading} />;
    } else if (user === null || (user.isAnonymous && location.pathname === ClientPath.Verify)) {
        if (isWebPlatform() || isChromeExtensionPlatform()) {
            return (
                <>
                    <Routes>
                        <Route path={ClientPath.VerifyGoogleOneTap} element={<VerifyGoogleOneTap />} />
                        <Route path={ClientPath.VerifyGoogleCode} element={<VerifyGoogleCode />} />
                        <Route path={ClientPath.VerifyMicrosoftCode} element={<VerifyMicrosoftCode />} />
                        <Route path={ClientPath.Verify} element={<WebAuthRouter />} />
                        <Route path={ClientPath.TrySpinach} element={<TrySpinachContainer />} />
                        <Route path={ClientPath.Unknown} element={<WebAuthRouter />} />
                    </Routes>
                    <SSOProvisioningInstructionsModal />
                </>
            );
        } else {
            return (
                <FYI
                    state={FYIState.Error}
                    header={'Bear with us'}
                    body={
                        'Your Spinach installation appears to be wilted. Please re-install from the Zoom App Marketplace.'
                    }
                />
            );
        }
    } else {
        return <AuthedUserRouter />;
    }
}
