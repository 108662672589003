import zoomSdk from '@zoom/appssdk';
import crypto from 'crypto';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { AiHistoryUserAccessKind, IClientUser, WebUrlQuery } from '@spinach-shared/types';
import { base64URLEncode } from '@spinach-shared/utils';

import { getUser, getUserEmailsWithHistoryAccess } from '../apis';
import { ClientPath } from '../components';
import { SetValue } from '../types';
import { ClientLogger, Globals, isZoomPlatform } from '../utils';
import { useGlobalRouting } from './useGlobalRouting';
import { useLocationalSeriesId } from './useGlobalSearchParams';
import { useGlobalUser } from './useGlobalUser';

/**
 *
 * @returns null if no user authed, undefined if still loading, and a ClientUser if user is authed
 */
export function useFetchUser(): [ClientUser | null | undefined, SetValue<IClientUser | null | undefined>] {
    // TODO: do we need hasFetched now that atom's default state is undefined
    const [hasFetched, setHasFetched] = useState(false);
    const [user, setUser] = useGlobalUser();
    const [params] = useSearchParams();
    const {
        routeToVerifyMicrosoftCode,
        routeToVerifyGoogleCode,
        routeToVerify,
        routeToVerifyGoogleOneTap,
        routeToAIDashboard,
        routeToDemoExperience,
        routeToSignUpExperience,
        routeToTrySpinach,
    } = useGlobalRouting();
    const locationalSeriesId = useLocationalSeriesId();
    const location = useLocation();
    const code = params.get(WebUrlQuery.Code);
    const credential = params.get(WebUrlQuery.Credential);
    useEffect(() => {
        async function exec(): Promise<void> {
            const userResponse = await getUser();

            const isInZoomAppInstallationFlow = userResponse.user === null && isZoomPlatform();
            if (isInZoomAppInstallationFlow) {
                try {
                    Globals.zoomInAppInstallOAuthVerifier = base64URLEncode(crypto.randomBytes(32));
                    await zoomSdk.authorize({
                        codeChallenge: Globals.zoomInAppInstallOAuthVerifier,
                    });

                    return;
                } catch (error) {
                    setHasFetched(true);
                    setUser(null);
                    ClientLogger.error('failed to call the in-zoom-app authorize API successfully');
                }
            }

            setHasFetched(true);
            setUser(userResponse.user);

            // if user is not authed, redirect to signin without history
            if (userResponse.user === null && !location.pathname.includes(ClientPath.TrySpinach)) {
                /**
                 * if a user is pasting a meeting/SPS*** URL, but are new or logged out,
                 * this will reuse the seriesId as a deep link so they still get directed
                 * to that series (and tracked as a joiner if new), rather than
                 * redirecting purely to /verify
                 */
                if (locationalSeriesId) {
                    const params = new URLSearchParams();
                    params.set(WebUrlQuery.Meeting, locationalSeriesId);
                    routeToVerify(undefined, params);
                } else if (location.pathname.includes(ClientPath.Control)) {
                    const params = new URLSearchParams();
                    params.set(WebUrlQuery.Redirect, location.pathname);
                    routeToVerify({ replace: true }, params);
                } else if (location.pathname.includes(ClientPath.VerifyGoogleCode) && code) {
                    // oauth flows
                    routeToVerifyGoogleCode();
                } else if (location.pathname.includes(ClientPath.VerifyGoogleOneTap) && credential) {
                    // oauth flows
                    routeToVerifyGoogleOneTap();
                } else if (location.pathname.includes(ClientPath.VerifyMicrosoftCode) && code) {
                    routeToVerifyMicrosoftCode();
                } else if (params.get(WebUrlQuery.BotId) && params.get(WebUrlQuery.SeriesId)) {
                    const botId = params.get(WebUrlQuery.BotId);
                    if (botId) {
                        const usersWithHistoryAccessResponse = await getUserEmailsWithHistoryAccess({ botId });
                        if (
                            usersWithHistoryAccessResponse?.usersWithHistoryAccess?.some(
                                (user) => user.kind === AiHistoryUserAccessKind.AnyoneWithLink
                            )
                        ) {
                            routeToTrySpinach();
                            return;
                        }
                    }
                    routeToVerify();
                } else {
                    routeToVerify();
                }
            }

            const clientUser = userResponse.user ? new ClientUser(userResponse.user) : null;

            if (clientUser?.isAnonymous && clientUser?.isScribeOnlyUser && location.pathname !== ClientPath.Verify) {
                routeToAIDashboard({ replace: true });
                return;
            }

            if (
                userResponse.user &&
                userResponse.user.metadata?.isAnonymousUser &&
                userResponse.user.seriesMetadataList?.length &&
                location.pathname !== ClientPath.Verify
            ) {
                routeToDemoExperience();
                return;
            }

            if (location.pathname.includes(ClientPath.SignUp) && userResponse.user === null) {
                routeToSignUpExperience();
            }
        }

        exec();
    }, []);

    return [hasFetched ? user : undefined, setUser];
}
