import { CustomSummaryTemplate, CustomSummaryTemplateData, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../..';

/**
 * Create a new custom summary template
 */
export async function postCreateCustomSummaryTemplate(
    templateData: CustomSummaryTemplateData,
    editingTemplateId?: string
): Promise<CustomSummaryTemplate | undefined> {
    const response = await postSpinachAPI<{ template: CustomSummaryTemplate }>(
        SpinachAPIPath.UserCustomSummaryTemplates,
        templateData,
        {
            params: {
                templateId: editingTemplateId,
            },
        }
    );

    return response?.template;
}
