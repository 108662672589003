import { useEffect, useRef, useState } from 'react';

import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI, postSpinachAPI } from '../../apis';
import { lightTheme } from '../../styles/theme';

export function Backoffice(): JSX.Element {
    const [logs, setLogs] = useState<string[]>([]);
    const logsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (logsContainerRef.current) {
            logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
        }
    }, [logs]);

    function addLogs(logs: string[], title: string) {
        // add with timestamp
        setLogs((prev) => [
            ...prev,
            '---------------------------------------------------------------- ' + title,
            ...logs.map((log) => '[' + new Date().toISOString() + '] ' + log),
        ]);
    }

    const buttons = [
        {
            label: 'Get user subscription',
            description: 'Get user subscription details including bundles',
            onClick: async () => {
                try {
                    const response = await getSpinachAPI<{ details: string[] }>(
                        SpinachAPIPath.BackofficeGetUserSubscription,
                        {
                            throwOnError: true,
                        }
                    );
                    if (response?.details) {
                        addLogs(response.details, 'Get user subscription');
                    } else {
                        addLogs(['No details in response'], 'Get user subscription');
                    }
                } catch (error) {
                    addLogs(['Error: ' + (error as Error).message], 'Get user subscription');
                }
            },
        },
        {
            label: 'Set user to unpaid',
            description: (
                <>
                    Set user and account to be unpaid
                    <br />
                    Set team to be inactive (if applicable)
                    <br />
                    Delete all bundles
                    <br />
                    Delete stripe customer and subscription
                    <br />
                </>
            ),
            onClick: async () => {
                try {
                    const response = await postSpinachAPI<{ details: string[] }>(
                        SpinachAPIPath.BackofficeSetUserToUnpaid,
                        {},
                        {
                            throwOnError: true,
                        }
                    );
                    if (response?.details) {
                        addLogs(response.details, 'Set user to unpaid');
                    } else {
                        addLogs(['No details in response'], 'Set user to unpaid');
                    }
                } catch (error) {
                    addLogs(['Error: ' + (error as Error).message], 'Set user to unpaid');
                }
            },
        },
        {
            label: 'Get or create MCP URL',
            description: 'Get or create MCP URL',
            onClick: async () => {
                try {
                    const response = await postSpinachAPI<{ mcpUrl: string }>(
                        SpinachAPIPath.MCPSettings,
                        {},
                        {
                            throwOnError: true,
                        }
                    );
                    if (response?.mcpUrl) {
                        addLogs([`MCP URL: ${response.mcpUrl}`], 'Get or create MCP URL');
                    } else {
                        addLogs(['No details in response'], 'Get or create MCP URL');
                    }
                } catch (error) {
                    addLogs(['Error: ' + (error as Error).message], 'Get or create MCP URL');
                }
            },
        },
    ];

    return (
        <div style={{ padding: '20px', display: 'flex', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '300px' }}>
                {buttons.map((button, index) => (
                    <div key={index} style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '4px' }}>
                        <button
                            onClick={button.onClick}
                            style={{
                                backgroundColor: lightTheme.primary.greenLight,
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                width: '100%',
                            }}
                        >
                            {button.label}
                        </button>
                        <p style={{ marginTop: '8px', color: '#666', fontSize: '14px' }}>{button.description}</p>
                    </div>
                ))}
            </div>

            <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '15px', flex: 1 }}>
                <h2 style={{ marginBottom: '10px' }}>Logs</h2>
                <div
                    ref={logsContainerRef}
                    style={{
                        height: '600px',
                        overflowY: 'auto',
                        backgroundColor: '#f5f5f5',
                        padding: '10px',
                        borderRadius: '4px',
                        fontFamily: 'Consolas, Monaco, "Courier New", monospace',
                    }}
                >
                    {logs.map((log, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '4px',
                                fontFamily: 'Consolas, Monaco, "Courier New", monospace',
                                fontSize: '12px',
                            }}
                        >
                            {log}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
