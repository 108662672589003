import React from 'react';
import styled from 'styled-components';

import { Column } from '../../../common';
import { SidebarRecorder } from '../recording/SidebarRecorder';

const QuickRecordContainer = styled(Column)`
    width: calc(100% - 40px);
    height: 100%;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 20px;
`;

export function QuickRecordSection(): JSX.Element {
    return (
        <QuickRecordContainer>
            <Title>Quick Record</Title>
            <SidebarRecorder />
        </QuickRecordContainer>
    );
}
