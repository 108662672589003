import { Box, CircularProgress, Divider, IconButton, TextField } from '@material-ui/core';
import { ArrowForward, InfoOutlined, Lock } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import { Autocomplete } from '@material-ui/lab';
import DOMPurify from 'dompurify';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { DAYS_IN_YEAR, DashboardSection } from '@spinach-shared/constants';
import { demoBotId, getDemoData } from '@spinach-shared/constants';
import {
    AiHistoryUserAccessKind,
    ClientAiHistory,
    ClientEventType,
    ClientUsersWithAiHistoryAccess,
    FeatureToggle,
    Media,
    MeetingSelection,
    NO_ACCESS_PREFIX,
    NotesStatus,
    ScribeMeetingType,
    SummaryJson,
    TranscriptData,
    WebUrlQuery,
} from '@spinach-shared/types';
import { formatTime, getDemoSelection } from '@spinach-shared/utils';

import {
    getAiSummaryJson,
    getAskSpinachRealtimeConfig,
    getUserEmailsWithHistoryAccess,
    patchUser,
    postNotifyMeetingParticipants,
} from '../../../../apis';
import { getAiHistories, getAiSummaryHtml, getAiTranscript } from '../../../../apis/getAiHistories';
import { getMedia } from '../../../../apis/getMedia';
import { GlobalModal } from '../../../../atoms';
import { ElementId } from '../../../../constants';
import {
    useAskSpinachEnabledQueries,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    usePrevious,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../../styles';
import { HeaderThree } from '../../../../styles/typography';
import { ButtonProps, SetValue } from '../../../../types';
import { ClientLogger, URLUtil, copyTextToClipboard, withContentMasking } from '../../../../utils';
import { initFullstory } from '../../../../utils/fullstory';
import { Anchor, Badge, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { InformativeBanner } from '../../../common/InformativeBanner';
import { ConfirmDeleteMeetingModal } from '../../../common/modals';
import { ScrollArea } from '../../../stand-up';
import { PrimaryButton } from '../../../stand-up/PrimaryButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import '../meetings/MeetingSection.css';
import { isUnpublishedDraft } from '../utils';
import { ActionsSectionContainer } from './ActionsSection';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import { MeetingMedia } from './MediaComponent';
import { MeetingChapters } from './MeetingChapters';
import { ShareSummaryRow } from './ShareSummaryRow';
import { SummaryTabContentContainer } from './SummaryTabContentContainer';
import { VideoSectionShimmer, AskSpinachShimmer, SummaryShimmer, TranscriptShimmer } from './TranscriptSectionShimmer';

// Enum for meeting badge labels to make programmatic checks safer
export enum MeetingBadgeLabel {
    Restricted = 'Restricted',
    Draft = 'Draft',
    Unpublished = 'Unpublished',
    Sample = 'Sample',
}

const SELECTION_CODE_DELIMITER = '::';

const CustomScrollArea = styled(ScrollArea)`
    padding: unset;
    scrollbar-width: none;
`;

export const getBlurbId = (index: number) => `blurb-${index}`;

export function getHistorySelectionCode(seriesId: string, botId: string): string {
    return `${seriesId}${SELECTION_CODE_DELIMITER}${botId}`;
}

function getHistorySelectionCodeComponents(historySelectionCode: string): { seriesId: string; botId: string } {
    const [seriesId, botId] = historySelectionCode.split(SELECTION_CODE_DELIMITER);
    return { seriesId, botId };
}

const mapHistoryToSelection = (h: ClientAiHistory): MeetingSelection => ({
    botId: h.botId,
    seriesId: h.seriesId,
    code: getHistorySelectionCode(h.seriesId, h.botId),
    label: `${h.meetingTitle}`,
    verbalDate: moment(h.customMeetingDate || h.createdAt).format('dddd, MMM Do YYYY'),
    title: h.meetingTitle,
    createdAt: new Date(h.customMeetingDate || h.createdAt),
    isPartialCappedSummary: h.isPartialCappedSummary,
    icpId: h.icpId,
    ticket: h.ticket,
    isEdited: h.isEdited,
    isEnabledForEditSummary: h.isEnabledForEditSummary,
    isEditor: h.isEditor,
    notesStatus: h.notesStatus,
    meetingType: h.meetingType,
});

// Function to create a load more option
const LOAD_MORE_OPTION_CODE = 'load-more-option';
const createLoadMoreOption = (): MeetingSelection => ({
    botId: 'load-more',
    seriesId: 'load-more',
    code: LOAD_MORE_OPTION_CODE,
    label: ``,
    verbalDate: '',
    title: '',
    createdAt: new Date(0), // Old date to ensure it appears at the end
    icpId: '',
    meetingType: ScribeMeetingType.Generic,
    ticket: {
        signature: '',
        expiration: 0,
    },
});

function isLoadMoreOption(option: MeetingSelection): boolean {
    return option.code === LOAD_MORE_OPTION_CODE;
}

const useFetchUsersWithHistoryAccess = ({
    botId,
}: {
    botId?: string;
}): [ClientUsersWithAiHistoryAccess[] | null, SetValue<ClientUsersWithAiHistoryAccess[] | null>] => {
    const [usersWithHistoryAccess, setUsersWithHistoryAccess] = useState<ClientUsersWithAiHistoryAccess[] | null>(null);
    const [globalModal] = useGlobalModal();
    const prevGlobalModal = usePrevious(globalModal);
    const prevBotId = usePrevious(botId);
    const [user] = useGlobalAuthedUser();
    useEffect(() => {
        const fetch = async (botId: string) => {
            // the demo meeting is hardcoded so there is nothing to fetch
            if (botId === demoBotId) {
                setUsersWithHistoryAccess([
                    {
                        email: user.email,
                        kind: AiHistoryUserAccessKind.Shared,
                    },
                ]);
                return;
            }
            const usersWithHistoryAccessResponse = await getUserEmailsWithHistoryAccess({ botId });
            if (usersWithHistoryAccessResponse?.usersWithHistoryAccess) {
                setUsersWithHistoryAccess(usersWithHistoryAccessResponse.usersWithHistoryAccess);
            } else {
                setUsersWithHistoryAccess(null);
            }
        };
        if (botId && (botId !== prevBotId || (prevGlobalModal === GlobalModal.ShareAiHistory && !globalModal))) {
            fetch(botId);
        }
    }, [globalModal, botId]);
    return [usersWithHistoryAccess, setUsersWithHistoryAccess];
};

// Add this type near the top of the file
type VideoMode = 'full' | 'highlights';

export function TranscriptsSection(): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [searchParams, setSearchParams] = useSearchParams();
    const { routeToScribeOnboarding } = useGlobalRouting();
    const { width, height, isDesktopView } = useWindowSize();
    const [, setGlobalModal] = useGlobalModal();
    const navigate = useNavigate();
    const [isRequestingAccess, setIsRequestingAccess] = useState(false);
    const {
        activeSection,
        setActiveSection,
        setToastText,
        setIsOnDemoNotes,
        meetingOptions: options,
        setMeetingOptions: setOptions,
        state: { pendingBotId, isLoadingPendingBot },
        setState,
    } = useGlobalAiDashboard();

    const [results, setResults] = useState<TranscriptData | null>(null);
    const [minimalTranscriptResponse, setMinimalTranscriptResponse] = useState<string[] | null>(null);
    const [selection, setSelection] = useState<MeetingSelection | null>(null);
    const [media, setMedia] = useState<Media>();
    const latestRequestIdRef = useRef<number>(0);

    const [summaryHtml, setSummaryHtml] = useState('');
    const cleanSummaryHtml = React.useMemo(
        () =>
            DOMPurify.sanitize(summaryHtml, {
                USE_PROFILES: { html: true },
                ADD_ATTR: ['target'],
            }),
        [summaryHtml]
    );
    const [summaryJson, setSummaryJson] = useState<SummaryJson | null>(null);

    const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
    const [isLoadingHistories, setIsLoadingHistories] = useState(false);
    const [isLoadingSummaryJson, setIsLoadingSummaryJson] = useState(false);

    // Add cursor state for pagination
    const [createdAtPageStart, setCreatedAtPageStart] = useState<string | undefined>(undefined);
    const [hasMoreMeetings, setHasMoreMeetings] = useState(true);
    const [isLoadingMoreMeetings, setIsLoadingMoreMeetings] = useState(false);

    // Track load count for progressive chunk sizing
    const [loadCount, setLoadCount] = useState(0);

    const shouldShowTicketSuggestions = user.isActionItemTicketCreationEnabled;
    const isMeetingChaptersVisibleOnDashboard = user.isMeetingChaptersVisibleOnDashboard;

    const isLoading =
        isLoadingHistories || isLoadingTranscript || (isLoadingSummaryJson && shouldShowTicketSuggestions);

    const hasProFeatures = useProFeatures();
    const freeOptionsCodes = options.slice(0, 3).map((option) => option.code);
    const unlimitedMeetingHistoryForFreeUsers = user.isEnabledForUnlimitedMeetingHistoryForFreeUsers;
    const isSelectedTranscriptLimited =
        !hasProFeatures &&
        selection &&
        !freeOptionsCodes.includes(selection.code) &&
        !unlimitedMeetingHistoryForFreeUsers;
    const navigateToUpgrade = useNavigateToUpgrade();
    const [botId, setBotId] = useState<string | undefined>(undefined);
    const [seriesId, setSeriesId] = useState<string | undefined>(undefined);

    const [usersWithHistoryAccess] = useFetchUsersWithHistoryAccess({ botId });

    // Add new state for video mode
    const [videoMode, setVideoMode] = useState<VideoMode>('highlights');

    // Update URL params if botId has changed
    function updateUrlParams(seriesId: string, botId: string) {
        if (botId !== searchParams.get(WebUrlQuery.BotId)) {
            const newSearchParams = new URLSearchParams(location.search);
            newSearchParams.set(WebUrlQuery.SeriesId, seriesId);
            newSearchParams.set(WebUrlQuery.BotId, botId);

            navigate(
                {
                    pathname: location.pathname,
                    search: newSearchParams.toString(),
                },
                { relative: 'path' }
            );
        }
    }

    async function fetchSelectedResults(selection: { code: string; label: string }) {
        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        updateUrlParams(seriesId, botId);
        await fetchSelectedResultsByIds(seriesId, botId);
    }

    async function fetchSelectedResultsByIds(seriesId: string, botId: string) {
        // help prevent race conditions with users fetching different notes over each other
        const currentRequestId = latestRequestIdRef.current + 1;
        latestRequestIdRef.current = currentRequestId;

        setIsLoadingTranscript(true);
        setIsLoadingSummaryJson(true);
        setMedia({ loading: true });
        setSummaryJson(null);
        setResults(null);
        setSummaryHtml('');
        setMinimalTranscriptResponse(null);
        setSecond(0);
        // Reset videoMode to ensure proper initialization for the new meeting
        const defaultToClips = user.featureToggles[FeatureToggle.DefaultMediaTypeClips] ?? false;
        setVideoMode(defaultToClips ? 'highlights' : 'full');
        const isDemo = botId === demoBotId;
        setIsOnDemoNotes(isDemo);
        const [transcriptResponse, askSpinachConfig, summaryHtmlResponse, summaryJsonResponse, media] = isDemo
            ? getDemoData({ username: user.firstName })
            : await Promise.all([
                  getAiTranscript({ seriesId, botId }),
                  getAskSpinachRealtimeConfig({ seriesId, botId }),
                  getAiSummaryHtml({ seriesId, botId }),
                  shouldShowTicketSuggestions || isMeetingChaptersVisibleOnDashboard
                      ? getAiSummaryJson(botId, false)
                      : undefined,
                  getMedia({ botId }),
              ]);

        if (currentRequestId !== latestRequestIdRef.current) {
            return;
        }

        setIsLoadingTranscript(false);
        setIsLoadingSummaryJson(false);
        setBotId(botId);
        setSeriesId(seriesId);
        if (summaryJsonResponse && 'summaryJson' in summaryJsonResponse) {
            setSummaryJson(summaryJsonResponse.summaryJson);
        } else {
            setSummaryJson(null);
        }

        if (askSpinachConfig?.minimalTranscript) {
            setMinimalTranscriptResponse(askSpinachConfig.minimalTranscript);
        } else {
            setMinimalTranscriptResponse(null);
        }

        if (transcriptResponse) {
            setResults(transcriptResponse);
        } else {
            setResults(null);
        }

        if (summaryHtmlResponse) {
            setSummaryHtml(summaryHtmlResponse);
        } else {
            setSummaryHtml('');
        }

        if (media) {
            setMedia(media);
        } else {
            setMedia({ error: true });
        }
    }

    function onDownloadVideo() {
        if (user.isNotSetupForFullDashboardFeatures) {
            setGlobalModal(GlobalModal.CreateAccount);
            return;
        }

        if (!hasProFeatures) {
            return;
        }
        if (!media || !('recordingUrl' in media)) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Download Video',
        });
        URLUtil.openURL(media.recordingUrl!);
    }

    function onCopyTranscript() {
        if (!selection) {
            return;
        }

        if (!results) {
            return;
        }
        if (results.isTranscriptDisabled) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(
            results.transcriptLineData
                .map((t) => `${formatTime(t.timestamp)} ${t.speaker}: ${t.statement}`)
                .join('\n\n')
        );
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Transcript Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Transcript copied to clipboard');
    }

    function onCopySummary() {
        if (!selection) {
            return;
        }

        if (!summaryHtml) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(summaryHtml);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Summary Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Summary copied to clipboard');
    }

    const readingSummaryLengthThresholdChars = 100;
    const readingSummaryMinimumTimeMs = 5000;
    useEffect(() => {
        if (
            activeSection === DashboardSection.Summaries &&
            results &&
            !results.isTranscriptDisabled &&
            results.transcriptLineData.length &&
            summaryHtml &&
            summaryHtml.length > readingSummaryLengthThresholdChars &&
            !user.metadata.hasReadAsummary
        ) {
            const timeout = setTimeout(async () => {
                const { user: updatedUser } = await patchUser({
                    metadata: { hasReadAsummary: true },
                });
                if (updatedUser) {
                    setUser(updatedUser);
                }
            }, readingSummaryMinimumTimeMs);
            return () => clearTimeout(timeout);
        }
    }, [activeSection, results, setUser, summaryHtml, user.metadata.hasReadAsummary]);

    useEffect(() => {
        if (
            selection?.isPartialCappedSummary &&
            !user.hasProFeatures &&
            activeSection !== DashboardSection.Chapters &&
            activeSection !== DashboardSection.Transcripts &&
            activeSection !== DashboardSection.AskSpinach
        ) {
            setActiveSection(DashboardSection.Chapters);
        }
    }, [activeSection, selection?.isPartialCappedSummary, setActiveSection, user.hasProFeatures]);

    const [lastFetchedIso, setLastFetchedIso] = useState<string | null>(new Date().toISOString());
    const shouldAllowFetching = useRef(true);

    // Calculate progressive chunk size based on load count
    function getProgressiveChunkSize(currentLoadCount: number): number | undefined {
        if (!user.isEnabledForMeetingHistoryPagination) {
            return undefined;
        }

        const optionsLength = options.length;
        // Progressive sizing logic
        if (currentLoadCount === 0) {
            return 50; // Initial load size
        } else {
            // Double the current options length for each subsequent load, up to 500
            return Math.min(optionsLength * 2, 300);
        }
    }

    // Function to load more meetings
    async function loadMoreMeetings() {
        if (!hasMoreMeetings || isLoadingMoreMeetings) {
            return;
        }

        setIsLoadingMoreMeetings(true);

        // Get dynamic chunk size based on current load count
        const chunkSize = getProgressiveChunkSize(loadCount);

        try {
            const historiesResponse = await getAiHistories({
                createdAtPageStart,
                limit: chunkSize,
            });

            if (historiesResponse?.histories) {
                const newMapped = historiesResponse.histories.map(mapHistoryToSelection);

                // Combine with existing options and maintain sort order
                const combinedOptions = [...options, ...newMapped];
                setOptions(combinedOptions.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));

                // Update cursor for next pagination request
                if (historiesResponse.nextCreatedAtPageStart) {
                    setCreatedAtPageStart(historiesResponse.nextCreatedAtPageStart);
                    setHasMoreMeetings(true);
                } else {
                    setHasMoreMeetings(false);
                }

                // Increment load count for next chunk sizing
                setLoadCount((prevLoadCount) => prevLoadCount + 1);

                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'More Transcripts Loaded',
                    TranscriptCount: newMapped.length,
                    ChunkSize: chunkSize,
                    LoadCount: loadCount + 1,
                });
            } else {
                setHasMoreMeetings(false);
            }
        } catch (error) {
            track(ClientEventType.AIDashboardUnhappyPath, {
                Flow: 'Load More Meetings',
                Error: (error as Error)?.message,
            });
            ClientLogger.error('Failed to load more meetings:', error);
        } finally {
            setIsLoadingMoreMeetings(false);
        }
    }

    useEffect(() => {
        async function exec() {
            let updateContentUponFetch = true;
            const deepSeriesId = searchParams.get(WebUrlQuery.SeriesId);
            const deepBotId = searchParams.get(WebUrlQuery.BotId);

            if (
                (!deepSeriesId && !deepBotId && !shouldAllowFetching.current) ||
                // no need to fetch if the notes are restricted
                (selection?.notesStatus?.startsWith(NO_ACCESS_PREFIX) && !shouldAllowFetching.current)
            ) {
                return;
            }

            shouldAllowFetching.current = false;

            setIsLoadingHistories(true);

            if (deepSeriesId && deepBotId) {
                const initialHistoryResponse = await getAiHistories({ botId: deepBotId });
                if (initialHistoryResponse?.histories && initialHistoryResponse.histories.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistoryResponse.histories[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            } else if (!deepSeriesId && !deepBotId) {
                const initialHistoryResponse = await getAiHistories({ limit: 1 });
                if (initialHistoryResponse?.histories && initialHistoryResponse.histories.length > 0) {
                    const initialMapped = mapHistoryToSelection(initialHistoryResponse.histories[0]);
                    setSelection(initialMapped);
                    await fetchSelectedResults(initialMapped);
                    updateContentUponFetch = false;
                }
            }

            // Use initial chunk size from progressive sizing logic for initial load
            const initialChunkSize = getProgressiveChunkSize(0);
            const historiesResponse = await getAiHistories({ limit: initialChunkSize });

            setIsLoadingHistories(false);

            if (!historiesResponse?.histories) {
                return;
            }

            const histories = historiesResponse.histories;

            // Store cursor for next page if available
            if (historiesResponse.nextCreatedAtPageStart) {
                setCreatedAtPageStart(historiesResponse.nextCreatedAtPageStart);
                setHasMoreMeetings(true);
            } else {
                setHasMoreMeetings(false);
            }

            const hasLimitedHistory = histories.length >= 1 && histories.length < 5;
            const forceDemo = searchParams.get('demo') === '1' || histories.length === 0;
            const mapped: MeetingSelection[] = histories.map(mapHistoryToSelection);

            // Add demo notes at the top if feature flag enabled or other conditions met
            if (user.isEnabledForPersistentDemoNotes || forceDemo || deepBotId === demoBotId || hasLimitedHistory) {
                if (hasLimitedHistory) {
                    mapped.push(getDemoSelection({ icpId: user._id }));
                } else if (mapped.length > 0 && user.isEnabledForPersistentDemoNotes) {
                    mapped.splice(1, 0, getDemoSelection({ icpId: user._id }));
                } else {
                    mapped.unshift(getDemoSelection({ icpId: user._id }));
                }
            }

            setOptions(mapped.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));

            // do not render demo notes if we've deep linked to a certain meeting without history
            if (forceDemo && updateContentUponFetch && !(deepSeriesId && deepBotId)) {
                setSelection(getDemoSelection({ icpId: user._id }));
                await fetchSelectedResults(getDemoSelection({ icpId: user._id }));
                return;
            }

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Transcripts Loaded',
                TranscriptCount: mapped.length,
            });

            const deepSelection =
                !!deepBotId && !!deepSeriesId
                    ? mapped.find((option) => option.code === getHistorySelectionCode(deepSeriesId, deepBotId))
                    : null;

            const isUnpaidTranscript =
                deepSelection?.code &&
                !hasProFeatures &&
                !mapped
                    .slice(0, 3)
                    .map((option) => option.code)
                    .includes(deepSelection.code);

            if (isUnpaidTranscript && !user.isEnabledForUnlimitedMeetingHistoryForFreeUsers) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Limited Transcript',
                });
                setSelection(deepSelection);
                setResults(null);
            } else if (
                deepSelection &&
                deepSeriesId &&
                (!!user.getSeriesById(deepSeriesId) || user.hasAdminUserContentAccess)
            ) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Transcript',
                });

                if (updateContentUponFetch) {
                    setSelection(deepSelection);
                    await fetchSelectedResults(deepSelection);
                }
            } else if (mapped[0]) {
                if (updateContentUponFetch) {
                    setSelection(mapped[0]);
                    await fetchSelectedResults(mapped[0]);
                }
            }
            if (pendingBotId) {
                // fetch the history for that bot
                const uploadedHistoryResponse = await getAiHistories({ botId: pendingBotId });
                if (uploadedHistoryResponse?.histories && uploadedHistoryResponse.histories.length > 0) {
                    const newSelection = mapHistoryToSelection(uploadedHistoryResponse.histories[0]);
                    setSelection(newSelection);
                    await fetchSelectedResults(newSelection);
                } else {
                    setToastText('Failed to load the meeting summary. Please try again.');
                }
                setState((prev) => ({
                    ...prev,
                    pendingBotId: undefined,
                    isLoadingPendingBot: false,
                }));
            }
        }
        exec();
    }, [lastFetchedIso, searchParams.get(WebUrlQuery.SeriesId), searchParams.get(WebUrlQuery.BotId), pendingBotId]);

    // Clear the navigation state once used.
    useEffect(() => {
        if (selection && searchParams.get(WebUrlQuery.EditSuccess)) {
            setSearchParams((searchParams) => {
                searchParams.delete(WebUrlQuery.EditSuccess);
                return searchParams;
            });
        }
    }, [searchParams, setSearchParams, selection]);

    useEffect(() => {
        const botId = searchParams.get(WebUrlQuery.BotId);
        const seriesId = searchParams.get(WebUrlQuery.SeriesId);

        const validSections = [
            DashboardSection.Transcripts,
            DashboardSection.Summaries,
            DashboardSection.Chapters,
            DashboardSection.Actions,
        ];

        if (botId && seriesId && validSections.includes(activeSection)) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Viewed Deep-Linked Notes',
                IsOnboarded: user.metadata.isOnboarded,
                BotId: botId,
                SeriesId: seriesId,
            });
        }
    }, []); // Empty dependency array ensures it only fires on mount

    const [currentSecond, setSecond] = useState(0);
    const videoRef = useRef<HTMLVideoElement>(null);

    const stackVideoWidth = width < 1200;
    const stackVideoLayout =
        stackVideoWidth || !('recordingUrl' in (media || {})) || 'isVideoRecordingDisabled' in (media || {});

    useEffect(() => {
        if (!stackVideoLayout && activeSection === DashboardSection.AskSpinach) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [stackVideoLayout, activeSection, setActiveSection]);

    useEffect(() => {
        if (
            selection &&
            summaryJson &&
            !summaryJson.meetingChapters?.length &&
            activeSection === DashboardSection.Chapters
        ) {
            setActiveSection(DashboardSection.Summaries);
        }
    }, [selection, summaryJson, activeSection, setActiveSection]);

    const askSpinachConfig = useAskSpinachEnabledQueries();
    const askSpinachEnabled = Object.keys(askSpinachConfig).length > 0;

    const lockedTabButtonProps: Partial<ButtonProps> = user.hasProFeatures
        ? {}
        : {
              preIcon: selection?.isPartialCappedSummary ? (
                  <Lock fontSize={'small'} htmlColor={lightTheme.tertiary.midnight} style={{ marginBottom: '-3px' }} />
              ) : undefined,
              disabled: !!selection?.isPartialCappedSummary,
          };

    const isShareAiHistoryEnabled = user.isShareAiHistoryEnabled;

    const getOptionLabelAndStatus = (option: MeetingSelection) => {
        const isLimited =
            !hasProFeatures && !freeOptionsCodes.includes(option.code) && !unlimitedMeetingHistoryForFreeUsers;

        let badgeLabel = '';

        if (option.notesStatus?.startsWith(NO_ACCESS_PREFIX)) {
            badgeLabel = MeetingBadgeLabel.Restricted;
        } else if (isUnpublishedDraft(option)) {
            if (option.isEditor) {
                badgeLabel = MeetingBadgeLabel.Draft;
            } else {
                badgeLabel = MeetingBadgeLabel.Unpublished;
            }
        } else if (option.botId === demoBotId) {
            badgeLabel = MeetingBadgeLabel.Sample;
        }

        return {
            label: isLimited ? `${option.label} (Spinach Pro Only)` : option.label,
            isLimited,
            isUnpublishedDraft: isUnpublishedDraft(option),
            badgeLabel,
        };
    };

    const [headerHeightState, setHeaderHeightState] = useState(0);

    const headerElement = document.getElementById(ElementId.MeetingNotesOverviewHeader);
    useEffect(() => {
        const height = headerElement?.getBoundingClientRect().height ?? 0;
        setHeaderHeightState(height);
    }, [headerElement?.getBoundingClientRect().height]);

    const heightOfSubTabRow = 51;

    // Modify the click handler for transcript lines
    function onTranscriptClick(timestamp: number) {
        if (activeSection === DashboardSection.Transcripts) {
            setVideoMode('full');
            // Use setTimeout to ensure the video element is in DOM before trying to play
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = timestamp + 0.3;
                    videoRef.current.play();
                }
            }, 0);
        }
    }

    function onChapterClick(second: number) {
        if (activeSection === DashboardSection.Chapters) {
            setVideoMode('full');
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.currentTime = second + 0.3;
                    videoRef.current.play();
                }
            }, 0);
        }
    }

    useEffect(() => {
        if (hasMoreMeetings && options.length > 0 && !options.some(isLoadMoreOption)) {
            setOptions([...options, createLoadMoreOption()]);
        }
    }, [hasMoreMeetings, options, setOptions]);

    return (
        <Box height="100%" display="flex" flexDirection="column" position="relative">
            {isLoadingPendingBot ? (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 1)"
                    zIndex={1000}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            <Box display="flex" flexDirection="column" id={ElementId.MeetingNotesOverviewHeader}>
                {!user.isAnonymous ? (
                    <>
                        <Spacing factor={2 / 3} />
                        <Row vCenter>
                            <Autocomplete
                                id="summaries-and-transcripts-id-selection"
                                options={options}
                                groupBy={(option) => {
                                    if (isLoadMoreOption(option)) {
                                        return '';
                                    }
                                    return moment(option.createdAt).format('YYYY/MM/DD');
                                }}
                                filterOptions={(options, state) => {
                                    // Always include the "Load More" option at the end if it exists and there are more meetings
                                    const filtered = options.filter((option) => {
                                        // If it's the load more option and we have more meetings, always include it
                                        if (isLoadMoreOption(option) && hasMoreMeetings) {
                                            return true;
                                        }

                                        // Otherwise apply the default filtering behavior
                                        const { label } = getOptionLabelAndStatus(option);
                                        const loweredInputValue = state.inputValue.toLowerCase();
                                        return (
                                            label.toLowerCase().includes(loweredInputValue) ||
                                            moment(option.createdAt)
                                                .format('dddd, MMMM D')
                                                .toLowerCase()
                                                .includes(loweredInputValue)
                                        );
                                    });

                                    // Make sure load more option is always at the end
                                    filtered.sort((a, b) => {
                                        if (isLoadMoreOption(a)) return 1;
                                        if (isLoadMoreOption(b)) return -1;
                                        return b.createdAt.getTime() - a.createdAt.getTime();
                                    });

                                    return filtered;
                                }}
                                renderGroup={(params) => {
                                    if (params.group === '') {
                                        return <>{params.children}</>;
                                    }
                                    return (
                                        <Column style={{ marginTop: '10px' }}>
                                            <BodyRegular
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '12px',
                                                    marginLeft: '15px',
                                                }}
                                            >
                                                {(() => {
                                                    const date = moment(params.group);
                                                    const currentYear = moment().year();
                                                    const dateYear = date.year();
                                                    const format =
                                                        currentYear !== dateYear
                                                            ? 'dddd, MMMM D, YYYY'
                                                            : 'dddd, MMMM D';
                                                    return date.format(format).toUpperCase();
                                                })()}
                                            </BodyRegular>
                                            {params.children}
                                        </Column>
                                    );
                                }}
                                renderOption={(option) => {
                                    if (isLoadMoreOption(option)) {
                                        return (
                                            <Row
                                                vCenter
                                                style={{ width: 'calc(100% - 30px)' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Load More Meetings From Dropdown',
                                                        MeetingCount: options.length,
                                                        LoadCount: loadCount,
                                                        ChunkSize: getProgressiveChunkSize(loadCount),
                                                    });
                                                    loadMoreMeetings();
                                                    return;
                                                }}
                                            >
                                                <BodyRegularOnboard
                                                    style={{
                                                        marginLeft: '10px',
                                                        color: lightTheme.primary.greenLight,
                                                        fontWeight: 500,
                                                        cursor:
                                                            isLoading || isLoadingMoreMeetings ? undefined : 'pointer',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {hasMoreMeetings ? (
                                                        <CachedIcon
                                                            style={{ fontSize: '16px', marginRight: '6px' }}
                                                            className={
                                                                isLoadingMoreMeetings
                                                                    ? 'refresh-upcoming-meetings'
                                                                    : undefined
                                                            }
                                                        />
                                                    ) : null}

                                                    {isLoadingMoreMeetings
                                                        ? 'Loading more meetings...'
                                                        : !hasMoreMeetings && user.dataRetentionInDays === DAYS_IN_YEAR
                                                        ? 'Showing all available meetings based on our 365-day data retention policy.'
                                                        : !hasMoreMeetings
                                                        ? `Showing all available meetings based on your ${user.dataRetentionInDays}-day data retention settings.`
                                                        : `Showing ${options.length} meetings. Click to load more.`}
                                                </BodyRegularOnboard>
                                            </Row>
                                        );
                                    }

                                    const { label, badgeLabel } = getOptionLabelAndStatus(option);

                                    return (
                                        <Row vCenter style={{ width: 'calc(100% - 30px)' }}>
                                            <BodyRegularOnboard style={{ marginLeft: '10px' }}>
                                                {label}
                                            </BodyRegularOnboard>
                                            {badgeLabel ? (
                                                <span style={{ marginLeft: '10px' }}>
                                                    <Badge title={badgeLabel} />
                                                </span>
                                            ) : null}
                                        </Row>
                                    );
                                }}
                                getOptionLabel={(option: MeetingSelection) => {
                                    return getOptionLabelAndStatus(option).label;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Choose a meeting"
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                fontWeight: 500,
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                fontWeight: 500,
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                            },
                                            startAdornment: (
                                                <>
                                                    {params.InputProps.startAdornment}
                                                    {selection && getOptionLabelAndStatus(selection).badgeLabel ? (
                                                        <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                            <Badge
                                                                title={getOptionLabelAndStatus(selection).badgeLabel}
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                value={selection}
                                size="small"
                                style={{
                                    minWidth: isDesktopView ? undefined : '60%',
                                    marginBottom: '10px',
                                    width: isDesktopView && !stackVideoWidth ? '78%' : 'calc(95% - 50px)',
                                    maxWidth: isDesktopView ? '1000px' : '600px',
                                }}
                                autoComplete
                                onChange={async (event, newSelection) => {
                                    // Skip if it's the load more option
                                    if (newSelection && isLoadMoreOption(newSelection)) {
                                        return;
                                    }

                                    setSelection(newSelection);

                                    if (!newSelection) {
                                        setSelection(null);
                                        setResults(null);
                                        setSummaryJson(null);
                                        setMedia(undefined);
                                        setSummaryHtml('');
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Cleared Transcript Selection',
                                        });
                                        return;
                                    }

                                    // Initialize FullStory for Restricted and Unpublished badges
                                    const badgeLabel = getOptionLabelAndStatus(newSelection).badgeLabel;
                                    if (
                                        badgeLabel === MeetingBadgeLabel.Restricted ||
                                        badgeLabel === MeetingBadgeLabel.Unpublished
                                    ) {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Selected ${badgeLabel} Meeting`,
                                        });
                                        initFullstory();
                                    }

                                    if (
                                        !hasProFeatures &&
                                        !freeOptionsCodes.includes(newSelection.code) &&
                                        !unlimitedMeetingHistoryForFreeUsers
                                    ) {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Selected Limited Transcript to Open',
                                        });
                                        setResults(null);
                                        setSummaryHtml('');
                                        return;
                                    }

                                    if (newSelection.notesStatus?.startsWith(NO_ACCESS_PREFIX)) {
                                        setMedia(undefined);
                                        setSummaryJson(null);
                                        setResults(null);
                                        setSummaryHtml('');
                                        setMinimalTranscriptResponse(null);
                                        setSecond(0);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Selected No Access Transcript to Open',
                                        });
                                        const { seriesId, botId } = getHistorySelectionCodeComponents(
                                            newSelection.code
                                        );
                                        updateUrlParams(seriesId, botId);
                                        return;
                                    }

                                    const { seriesId, botId } = getHistorySelectionCodeComponents(newSelection.code);
                                    updateUrlParams(seriesId, botId);

                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Selected Transcript to Open',
                                    });
                                }}
                                {...withContentMasking()}
                            />
                            <Column vCentered={true} style={{ width: '60px' }}>
                                <IconButton
                                    onClick={async () => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Refresh Calendar Events',
                                        });
                                        shouldAllowFetching.current = true;
                                        setLastFetchedIso(new Date().toISOString());
                                    }}
                                    style={{ marginTop: '-10px' }}
                                    disableRipple
                                    disabled={isLoading || isLoadingMoreMeetings}
                                    className={
                                        isLoading || isLoadingMoreMeetings ? 'refresh-upcoming-meetings' : undefined
                                    }
                                >
                                    <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                                </IconButton>
                            </Column>
                        </Row>
                    </>
                ) : null}
                {selection?.botId === demoBotId ? (
                    <>
                        <InformativeBanner
                            message={
                                <span style={{ color: lightTheme.primary.green, fontWeight: 'bold', fontSize: '16px' }}>
                                    This is an example meeting
                                </span>
                            }
                        />
                        <Spacing factor={1 / 3} />
                    </>
                ) : user.isNotSetupForFullDashboardFeatures ? (
                    <>
                        <InformativeBanner
                            icon={null}
                            message={
                                <span
                                    style={{ color: lightTheme.primary.midnight, fontSize: '18px', lineHeight: '1.2' }}
                                >
                                    Get started for <b>free</b> and never miss a meeting summary again!{' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Start Free Trial from Anonymous User Banner',
                                            });
                                            if (user.isAnonymous) {
                                                setGlobalModal(GlobalModal.CreateAccount);
                                            } else {
                                                routeToScribeOnboarding();
                                            }
                                        }}
                                    >
                                        Start Free trial
                                    </Anchor>{' '}
                                    <ArrowForward
                                        style={{ verticalAlign: 'middle', marginTop: '-5px' }}
                                        htmlColor={lightTheme.primary.green}
                                    />
                                </span>
                            }
                        />
                        <Spacing factor={1 / 3} />
                    </>
                ) : null}
                {isUnpublishedDraft(selection) && selection?.isEditor ? (
                    <Row
                        style={{
                            backgroundColor: '#EEF9F6',
                            borderRadius: '4px',
                            paddingLeft: '5px',
                            paddingBottom: '5px',
                            marginBottom: '10px',
                            maxWidth: '600px',
                        }}
                    >
                        <Row style={{ alignItems: 'center' }}>
                            <InfoOutlined style={{ color: '#4D9F8A', marginTop: '4px', marginRight: '10px' }} />
                            <BodyRegularOnboard style={{ fontWeight: 400, marginTop: '4px' }}>
                                This summary is an unpublished draft. Go to{' '}
                                <div style={{ display: 'inline-block' }}>
                                    <SecondaryButton
                                        title="Drafts"
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Go to Drafts from Histories',
                                            });
                                            setActiveSection(DashboardSection.Drafts);
                                        }}
                                    />
                                </div>{' '}
                                to edit and publish it.
                            </BodyRegularOnboard>
                        </Row>
                    </Row>
                ) : null}
            </Box>
            <Box
                display="flex"
                style={{ overflowY: stackVideoLayout && isDesktopView ? 'scroll' : 'initial' }}
                flexDirection={stackVideoLayout ? 'column' : 'row'}
                overflow="clip"
            >
                {botId && selection && ('recordingUrl' in (media || {}) || 'googleDriveUrl' in (media || {})) ? (
                    <MeetingMedia
                        media={media}
                        stackVideoLayout={stackVideoLayout}
                        onDownloadVideo={onDownloadVideo}
                        meetingSelection={selection}
                        freeOptionsCodes={freeOptionsCodes}
                        videoRef={videoRef}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        transcript={results ?? undefined}
                        dashboardSection={activeSection}
                        onTimeUpdate={(timestamp) => setSecond(timestamp)}
                        botId={botId}
                        seriesId={seriesId}
                        onTitleUpdate={(updatedSelection) => setSelection(updatedSelection)}
                        summaryJson={summaryJson}
                        videoMode={videoMode}
                        setVideoMode={setVideoMode}
                    />
                ) : media && 'loading' in media ? (
                    <VideoSectionShimmer />
                ) : (
                    <></>
                )}
                {selection?.notesStatus?.startsWith(NO_ACCESS_PREFIX) ? (
                    <Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="start"
                            justifyContent="center"
                            width="100%"
                        >
                            <HeaderThree>
                                {selection.title} ({selection.verbalDate})
                            </HeaderThree>
                            <Box
                                display="flex"
                                alignItems="center"
                                bgcolor="#EEF9F6"
                                p={2}
                                borderRadius={8}
                                mb={2}
                                mt={2}
                                mr={'20px'}
                            >
                                <InfoOutlined style={{ color: '#4D9F8A', marginRight: '8px' }} />
                                {selection?.notesStatus === NotesStatus.Unauthorized ? (
                                    <BodyRegular>
                                        You don't have access to these meeting notes. Request access to view this
                                        content.
                                    </BodyRegular>
                                ) : (
                                    <BodyRegular>
                                        Access to the meeting notes, recording, and transcript has been limited by the
                                        host.
                                    </BodyRegular>
                                )}
                            </Box>
                            <PrimaryButton
                                title="Request access"
                                isLoading={isRequestingAccess}
                                onClick={async () => {
                                    setIsRequestingAccess(true);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Request Access',
                                        MeetingTitle: selection.title,
                                    });

                                    try {
                                        await postNotifyMeetingParticipants(selection.botId, 'request-access');
                                        setToastText('Access request sent to meeting host');
                                    } catch (error) {
                                        track(ClientEventType.AIDashboardUnhappyPath, {
                                            Error: error,
                                            Flow: 'Request Access to Meeting Notes',
                                        });
                                        setToastText('Failed to send request. Please try again later.');
                                    } finally {
                                        setIsRequestingAccess(false);
                                    }
                                }}
                            />
                            <Spacing />
                        </Box>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex={stackVideoLayout ? undefined : 6}
                        mt={stackVideoLayout ? '10px' : '0px'}
                        overflow="clip"
                    >
                        <CustomScrollArea
                            horizontal
                            style={{
                                flexDirection: 'row',
                                minWidth: 'calc(60% + 40px)',
                            }}
                        >
                            {(shouldShowTicketSuggestions && summaryJson && !user.isNotSetupForFullDashboardFeatures) ||
                            (selection?.isPartialCappedSummary &&
                                !user.hasProFeatures &&
                                !user.isNotSetupForFullDashboardFeatures) ? (
                                <BootstrapTooltip title={lockedTabButtonProps.disabled ? 'Upgrade to access' : ''}>
                                    <Box style={{ whiteSpace: 'nowrap' }}>
                                        <SecondaryButton
                                            title="Actions"
                                            buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                            color={
                                                activeSection === DashboardSection.Actions
                                                    ? lightTheme.primary.greenLight
                                                    : lightTheme.primary.midnight
                                            }
                                            onClick={() => {
                                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Action Tab' });
                                                setActiveSection(DashboardSection.Actions);
                                            }}
                                            {...lockedTabButtonProps}
                                        />
                                    </Box>
                                </BootstrapTooltip>
                            ) : (
                                <></>
                            )}
                            <BootstrapTooltip title={lockedTabButtonProps.disabled ? 'Upgrade to access' : ''}>
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    <SecondaryButton
                                        title="Summary"
                                        buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                        color={
                                            activeSection === DashboardSection.Summaries
                                                ? lightTheme.primary.greenLight
                                                : lightTheme.primary.midnight
                                        }
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summary Tab' });
                                            setActiveSection(DashboardSection.Summaries);
                                        }}
                                        {...lockedTabButtonProps}
                                    />
                                </Box>
                            </BootstrapTooltip>
                            {(isMeetingChaptersVisibleOnDashboard || selection?.isPartialCappedSummary) &&
                            summaryJson?.meetingChapters?.length ? (
                                <Box style={{ whiteSpace: 'nowrap' }}>
                                    <SecondaryButton
                                        title="Chapters"
                                        buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                        color={
                                            activeSection === DashboardSection.Chapters
                                                ? lightTheme.primary.greenLight
                                                : lightTheme.primary.midnight
                                        }
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Chapters Tab',
                                            });
                                            setActiveSection(DashboardSection.Chapters);
                                        }}
                                    />
                                </Box>
                            ) : null}
                            {!!results && !results.isTranscriptDisabled && results.transcriptLineData.length ? (
                                <>
                                    <Box style={{ whiteSpace: 'nowrap' }}>
                                        <SecondaryButton
                                            title="Transcript"
                                            buttonStyles={{ textDecoration: 'none' }}
                                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                            color={
                                                activeSection === DashboardSection.Transcripts
                                                    ? lightTheme.primary.greenLight
                                                    : lightTheme.primary.midnight
                                            }
                                            onClick={() => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Transcript Tab',
                                                });
                                                setActiveSection(DashboardSection.Transcripts);
                                            }}
                                        />
                                    </Box>

                                    {stackVideoLayout && askSpinachEnabled ? (
                                        <Box style={{ whiteSpace: 'nowrap' }}>
                                            <SecondaryButton
                                                title="Ask Spinach"
                                                buttonStyles={{ textDecoration: 'none', marginLeft: '30px' }}
                                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                                color={
                                                    activeSection === DashboardSection.AskSpinach
                                                        ? lightTheme.primary.greenLight
                                                        : lightTheme.primary.midnight
                                                }
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Ask Spinach Tab',
                                                    });
                                                    setActiveSection(DashboardSection.AskSpinach);
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : null}
                            <Spacing factor={1 / 2} horizontal />
                            {botId && seriesId && usersWithHistoryAccess && isShareAiHistoryEnabled && selection ? (
                                <ShareSummaryRow
                                    seriesId={seriesId}
                                    meetingTitle={selection.title}
                                    hostId={selection.icpId}
                                    onCopyTranscript={onCopyTranscript}
                                    shouldShowCopyTranscript={Boolean(
                                        results && !results.isTranscriptDisabled && results.transcriptLineData.length
                                    )}
                                    shouldShowDownloadVideo={Boolean(
                                        media && 'recordingUrl' in media && !!media.recordingUrl
                                    )}
                                    onCopySummary={onCopySummary}
                                    botId={botId}
                                    usersWithHistoryAccess={usersWithHistoryAccess}
                                    onDownloadVideo={onDownloadVideo}
                                />
                            ) : (
                                <></>
                            )}
                            <span style={{ width: '40px' }} />
                        </CustomScrollArea>

                        <Divider style={{ marginTop: '10px' }} />
                        <Box flex={1} display="flex" flexDirection="column" overflow="clip">
                            {(!!results && Array.isArray(results) && results.length) || !!summaryHtml ? (
                                <>
                                    <ScrollArea
                                        sidePadding={0}
                                        style={{
                                            paddingTop: '10px',
                                            width: '100%',
                                            overflowY: isDesktopView && !stackVideoLayout ? 'scroll' : 'unset',
                                            height:
                                                isDesktopView && !stackVideoLayout
                                                    ? `${height - headerHeightState - heightOfSubTabRow}px`
                                                    : 'unset',
                                        }}
                                    >
                                        {activeSection === DashboardSection.Actions && selection ? (
                                            <ActionsSectionContainer
                                                summaryJson={summaryJson}
                                                setSummaryJson={setSummaryJson}
                                                botId={getHistorySelectionCodeComponents(selection.code).botId}
                                                selection={selection}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        {botId && activeSection === DashboardSection.AskSpinach ? (
                                            isLoadingTranscript ? (
                                                <AskSpinachShimmer />
                                            ) : (
                                                <AskSpinach
                                                    from={AskSpinachFrom.MeetingHistory}
                                                    botId={botId}
                                                    minimalTranscriptResponse={minimalTranscriptResponse}
                                                    seriesId={seriesId}
                                                />
                                            )
                                        ) : (
                                            <></>
                                        )}
                                        {activeSection === DashboardSection.Summaries ? (
                                            isLoadingTranscript || isLoadingSummaryJson ? (
                                                <SummaryShimmer />
                                            ) : (
                                                <SummaryTabContentContainer
                                                    cleanSummaryHtml={cleanSummaryHtml}
                                                    stackVideoLayout={stackVideoLayout}
                                                    withContentMasking={withContentMasking}
                                                    selection={selection}
                                                />
                                            )
                                        ) : null}
                                        {activeSection === DashboardSection.Transcripts ? (
                                            isLoadingTranscript ? (
                                                <TranscriptShimmer />
                                            ) : (
                                                !results?.isTranscriptDisabled && results?.transcriptLineData ? (
                                                    results.transcriptLineData.map((blurb, i) => {
                                                        const isActive =
                                                            currentSecond >= blurb.timestamp &&
                                                            (results.transcriptLineData[i + 1] == undefined ||
                                                                currentSecond <=
                                                                    results.transcriptLineData[i + 1].timestamp);
                                                        return (
                                                            <Column
                                                                key={i}
                                                                onClick={() => {
                                                                    onTranscriptClick(blurb.timestamp);
                                                                }}
                                                                id={getBlurbId(i)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    marginBottom: '10px',
                                                                    background: isActive
                                                                        ? 'rgba(53, 162, 137, 0.5)'
                                                                        : undefined,
                                                                }}
                                                            >
                                                                <Row>
                                                                    <span style={{ color: 'gray', minWidth: '45px' }}>
                                                                        {formatTime(blurb.timestamp)}
                                                                    </span>
                                                                    <b
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            minWidth: '150px',
                                                                            marginRight: '5px',
                                                                        }}
                                                                        {...withContentMasking()}
                                                                    >
                                                                        {blurb.speaker}
                                                                    </b>
                                                                </Row>
                                                                <Row
                                                                    style={{
                                                                        marginLeft: '50px',
                                                                        width: 'calc(100% -  50px)',
                                                                    }}
                                                                    {...withContentMasking()}
                                                                >
                                                                    {blurb.statement}
                                                                </Row>
                                                            </Column>
                                                        );
                                                    })
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        ) : null}
                                        {activeSection === DashboardSection.Chapters ? (
                                            <MeetingChapters
                                                botId={botId}
                                                seriesId={seriesId}
                                                chapters={summaryJson?.meetingChapters}
                                                playAtSecond={(second) => {
                                                    onChapterClick(second);
                                                }}
                                                currentTime={
                                                    videoRef.current?.paused || videoRef.current?.ended
                                                        ? undefined
                                                        : currentSecond
                                                }
                                            />
                                        ) : null}
                                    </ScrollArea>
                                </>
                            ) : !options.length && !isLoadingHistories ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <BodyRegular>No summaries or transcripts available.</BodyRegular>
                                    </Row>
                                </>
                            ) : isSelectedTranscriptLimited ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <BodyRegular>
                                            You can access older summaries and transcripts by{' '}
                                            <Anchor
                                                onClick={() => {
                                                    navigateToUpgrade('Click Upgrade (Opened Limited Transcript)');
                                                }}
                                            >
                                                Upgrading
                                            </Anchor>
                                            .
                                        </BodyRegular>
                                    </Row>
                                </>
                            ) : (!results || (Array.isArray(results) && !results.length)) &&
                              !isLoadingTranscript &&
                              !!options.length &&
                              !!selection ? (
                                <>
                                    <Spacing factor={1 / 2} />
                                    <Row>
                                        <BodyRegular>No summary or transcript found for this meeting.</BodyRegular>
                                    </Row>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                )}
            </Box>
            {selection ? <ConfirmDeleteMeetingModal selection={selection} /> : null}
        </Box>
    );
}
