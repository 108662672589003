import { useQuery } from 'react-query';

import { ClientMCPUserSettings, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../apis/postSpinachAPI';
import { useGlobalAuthedUser } from './useGlobalUser';

async function fetchMcpServerUrl() {
    const response = await postSpinachAPI<{ mcpUserSettings: ClientMCPUserSettings; mcpUrl: string }>(
        SpinachAPIPath.MCPSettings,
        {}
    );

    return response?.mcpUrl;
}

export function useGlobalMcpServerUrlKey(shouldFetch: boolean) {
    const [user] = useGlobalAuthedUser();
    const query = useQuery({
        queryKey: 'mcp-server-url',
        queryFn: () => fetchMcpServerUrl(),
        enabled: shouldFetch && user.isMcpIntegrationEnabled,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retry: 2,
    });
    return query;
}
