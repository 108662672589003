import { Box, CircularProgress, IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import { Delete as DeleteIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ErrorIcon from '@material-ui/icons/Error';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
    ENGLISH_LANGUAGE,
    MEETING_LANGAUGES,
    MEETING_LANGUAGE_SEPARATOR,
    OUTPUT_LANGUAGES,
    languageCodeMap,
    supportedUploadFileTypes,
} from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { CheckoutSessionPlanType, FileUpload, FileUploadResponse, FileUploadStatus } from '@spinach-shared/types';
import { SpinachAPIPath } from '@spinach-shared/types/src/APIs';
import { ScribeMeetingType } from '@spinach-shared/types/src/Series';
import { TimeUtils, isMeetingTypeEnabledForEditing } from '@spinach-shared/utils';

import {
    Badge,
    BodyRegularOnboard,
    BootstrapTooltip,
    ButtonSize,
    Column,
    DropDown,
    GlobalModal,
    HeaderThree,
    HeaderThreeOnboard,
    ListItemValue,
    ResponsiveModalTitle,
    Row,
    Spacing,
    getSpinachAPI,
    initFullstory,
    lightTheme,
    postSpinachAPI,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalCustomTemplates,
    useGlobalModal,
    useIncludeUserResearchInterviewInMeetingTypeSelection,
} from '../../../../..';
import { withContentMasking } from '../../../../utils/withContentMasking';
import { SpinachModalContent } from '../../../common/SpinachModalContent';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { PrimaryButton } from '../../../stand-up/PrimaryButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { DeletableTag, NewTag } from '../integrations/CustomVocabularySection';
import { formatDuration } from '../recording/audioUtils';
import { useLocalRecordings } from '../recording/useLocalRecordings';
import { MeetingTypeSelector } from './MeetingTypeSelector';

interface UploadUsage {
    maxUploadsPerWindow: number;
    currentUploads: number;
    type: CheckoutSessionPlanType;
    windowHours: number;
}

const useStyles = makeStyles((theme) => ({
    dropZone: {
        border: '1px dashed #ccc',
        borderRadius: '4px',
        padding: '40px 20px',
        textAlign: 'center',
        backgroundColor: '#fafafa',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            borderColor: '#999',
        },
    },
    uploadIcon: {
        fontSize: 48,
        color: '#666',
        marginBottom: theme.spacing(2),
    },
    fileInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
    },
    fileIcon: {
        width: '24px',
        height: '24px',
    },
    fileSize: {
        color: '#6B7280',
        marginLeft: '4px',
    },
    closeIcon: {
        cursor: 'pointer',
        color: '#4B5563',
        padding: '4px',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#F3F4F6',
        },
    },
    participantInput: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(2),
        marginBottom: 0,
        width: '100%',
        maxWidth: '600px',
        alignItems: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing(1),
            width: '100%',
            alignItems: 'center',
        },
    },
    labelSection: {
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        margin: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 0,
        },
    },
    tagsContainer: {
        borderBottom: `1px solid ${lightTheme.primary.orangeLight}`,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        gap: theme.spacing(1),
        alignItems: 'flex-start',
        minHeight: '40px',
        maxWidth: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%',
        },
    },
    uploadContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    fileDisplay: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '400px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 16px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        border: '1px solid #E5E7EB',
    },
    uploadLabel: {
        fontWeight: 500,
        whiteSpace: 'nowrap',
    },
    selectedFileContainer: {
        width: '90%',
        border: '1px solid #E5E7EB',
        borderRadius: '8px',
        padding: theme.spacing(3),
        backgroundColor: '#fafafa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    progressContainer: {
        width: '100%',
        maxWidth: '400px',
        textAlign: 'center',
    },
    uploadsContainer: {
        width: '100%',
    },
    uploadRow: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.15s ease-in-out',
        '&:hover': {
            transform: 'translateY(-1.5px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            borderColor: `${lightTheme.primary.greenLight}80`,
        },
    },
    localRecordingRow: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.15s ease-in-out',
    },
    uploadStatus: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    uploadDetails: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    uploadInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    fileNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        flex: 1,
    },
    fileNameText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    uploadSectionContainer: {
        overflowY: 'scroll',
        paddingBottom: '50px',
        paddingRight: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0px',
        },
    },
    uploadLimitContent: {
        padding: theme.spacing(3),
        maxWidth: '450px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    upgradeButton: {
        marginBottom: theme.spacing(2),
        width: '100%',
        height: '48px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        borderRadius: '4px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 500,
    },
    learnMoreText: {
        color: '#1B4D3E',
        cursor: 'pointer',
        fontSize: '16px',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    languageRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        gap: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(1),
        },
    },
    languageLabel: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '250px',
        fontWeight: 'bold',
        gap: theme.spacing(1),
        whiteSpace: 'nowrap',
        margin: 0,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: theme.spacing(2),
            marginBottom: '0px',
        },
    },
    dropdownContainer: {
        flex: 1,
        minWidth: '280px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 'unset',
        },
    },
    dropdownWrapper: {
        transform: 'scale(0.75)',
        transformOrigin: 'left center',
        '& .MuiButton-root': {
            minHeight: '36px',
            padding: '6px 12px',
        },
        '& .MuiButton-label': {
            fontSize: '14px',
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(1)',
        },
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            alignItems: 'flex-start',
        },
    },
    switchText: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        },
    },
    languageSelectionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 0,
        gap: '3px',
    },
    formSectionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 0,
        maxWidth: '650px',
    },
}));

const isStalledUpload = (upload: FileUpload) => {
    return (
        new Date(upload.createdAt).getTime() < new Date(Date.now() - 1000 * 60 * 60 * 24).getTime() &&
        upload.status !== FileUploadStatus.Completed &&
        upload.status !== FileUploadStatus.Error
    );
};

interface UploadHistoryTabProps {
    userUploads: FileUpload[];
    isLoadingUploads: boolean;
    handleUploadClick: (upload: FileUpload) => void;
    classes: ReturnType<typeof useStyles>;
}

function UploadHistoryTab({
    userUploads,
    isLoadingUploads,
    handleUploadClick,
    classes,
}: UploadHistoryTabProps): JSX.Element {
    return (
        <>
            <Box className={classes.uploadsContainer}>
                {userUploads
                    .filter((upload) => upload.status !== FileUploadStatus.Pending)
                    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                    .map((upload) => (
                        <div
                            key={upload._id}
                            className={classes.uploadRow}
                            style={{ cursor: upload.status === FileUploadStatus.Completed ? 'pointer' : 'initial' }}
                            onClick={() => handleUploadClick(upload)}
                        >
                            <div className={classes.uploadStatus}>
                                {upload.status === FileUploadStatus.Completed ? (
                                    <CheckCircleIcon style={{ color: lightTheme.status.positive }} />
                                ) : upload.status === FileUploadStatus.Error || isStalledUpload(upload) ? (
                                    <ErrorIcon style={{ color: lightTheme.status.negative }} />
                                ) : (
                                    <CircularProgress size={20} style={{ color: lightTheme.neutrals.midnight }} />
                                )}
                            </div>
                            <div className={classes.uploadDetails}>
                                <div {...withContentMasking(classes.fileName)}>
                                    <BodyRegularOnboard style={{ fontWeight: 600 }}>
                                        {`${upload.fileName || 'Unknown file'}${
                                            upload.generatedTitle ? ` - ${upload.generatedTitle}` : ''
                                        }`}
                                    </BodyRegularOnboard>
                                </div>
                                {upload.status === FileUploadStatus.Error || isStalledUpload(upload) ? (
                                    <BodyRegularOnboard style={{ color: lightTheme.status.negative }}>
                                        {isStalledUpload(upload)
                                            ? 'Processing timed out, please try again'
                                            : upload.errorMessage}
                                    </BodyRegularOnboard>
                                ) : null}
                                {!isStalledUpload(upload) &&
                                (upload.status === FileUploadStatus.Transcribing ||
                                    upload.status === FileUploadStatus.Processing) ? (
                                    <BodyRegularOnboard style={{ color: lightTheme.primary.orangeDark }}>
                                        {'Converting to notes and transcript...'}
                                    </BodyRegularOnboard>
                                ) : null}
                                <div className={classes.uploadInfo}>
                                    <Tooltip title={new Date(upload.createdAt).toLocaleString()}>
                                        <BodyRegularOnboard>
                                            {TimeUtils.getRelativeTimeString(new Date(upload.createdAt))}
                                        </BodyRegularOnboard>
                                    </Tooltip>
                                    <BodyRegularOnboard>
                                        {upload.fileSize ? `${Math.round(upload.fileSize / (1024 * 1024))} MB` : 'N/A'}
                                    </BodyRegularOnboard>
                                </div>
                            </div>
                        </div>
                    ))}
                {!isLoadingUploads && !userUploads.some((upload) => upload.status !== FileUploadStatus.Pending) ? (
                    <Box
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            padding: '20px 10px',
                            textAlign: 'center',
                            background: 'transparent',
                        }}
                    >
                        <BodyRegularOnboard>
                            No recent uploads. Start by uploading a recording to get a full meeting breakdown,
                            transcript, summary, and action items.
                        </BodyRegularOnboard>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}

function LocalRecordingsTab(): JSX.Element {
    const classes = useStyles();
    const { recordings, isLoading, downloadRecording, deleteRecording } = useLocalRecordings();

    const handleDownload = async (recordingId: string) => {
        const recording = recordings.find((r) => r.id === recordingId);
        if (recording) {
            await downloadRecording(recording);
        }
    };

    const handleDelete = async (recordingId: string) => {
        await deleteRecording(recordingId);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" padding={2}>
                <CircularProgress />
            </Box>
        );
    }

    if (recordings.length === 0) {
        return (
            <>
                <Spacing factor={1 / 4} />
                <BodyRegularOnboard>
                    No recent local recordings. Start by making a new recording using Quick Record in the sidebar.
                </BodyRegularOnboard>
            </>
        );
    }

    return (
        <>
            <Box className={classes.uploadsContainer}>
                {recordings
                    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                    .map((recording) => (
                        <div key={recording.id} className={classes.localRecordingRow}>
                            <div className={classes.uploadDetails}>
                                <div className={classes.fileName}>
                                    <BodyRegularOnboard style={{ fontWeight: 600 }}>
                                        {recording.name ||
                                            `Recording ${new Date(recording.createdAt).toLocaleString()}`}
                                    </BodyRegularOnboard>
                                </div>
                                <div className={classes.uploadInfo}>
                                    <Tooltip title={new Date(recording.createdAt).toLocaleString()}>
                                        <BodyRegularOnboard>
                                            {TimeUtils.getRelativeTimeString(new Date(recording.createdAt))}
                                        </BodyRegularOnboard>
                                    </Tooltip>
                                    <BodyRegularOnboard>{formatDuration(recording.duration)}</BodyRegularOnboard>
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px', marginLeft: '16px' }}>
                                <IconButton onClick={() => handleDownload(recording.id)} size="small">
                                    <GetAppIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(recording.id)} size="small">
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    ))}
            </Box>
        </>
    );
}

const TabContainer = styled.div`
    display: flex;
`;

const TabHeader = styled.div`
    margin-right: 32px;
    cursor: pointer;
    transition: all 0.3s ease;
`;

const TabHeaderText = styled.div<{ isSelected: boolean }>`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    color: ${(props) => (props.isSelected ? props.theme.primary.greenLight : props.theme.primary.midnight)};
`;

export function UploadSection(): JSX.Element {
    const {
        setToastText,
        audioRecordingBlob,
        setState: setGlobalAiDashboardState,
        recordingError,
        audioRecordingMimeType,
    } = useGlobalAiDashboard();

    const [user] = useGlobalAuthedUser();
    const [participantNames, setParticipantNames] = useState<Array<{ id: string; text: string }>>(
        user?.preferredName ? [{ id: uuid(), text: user.preferredName }] : []
    );
    const [pendingParticipant, setPendingParticipant] = useState('');
    const [inputActive, setInputActive] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const participantInputRef = useRef<HTMLInputElement>(null);
    const isSubmittedRef = useRef(false);
    const track = useExperienceTracking();
    const { openBotIdFromUploads } = useGlobalAiDashboard();
    const navigateToUpgrade = useNavigateToUpgrade();

    const [userUploads, setUserUploads] = useState<FileUpload[]>([]);
    const [isLoadingUploads, setIsLoadingUploads] = useState(true);
    const [uploadUsage, setUploadUsage] = useState<UploadUsage | null>(null);
    const [showUploadLimitModal, setShowUploadLimitModal] = useState(false);
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [displayedRecordingError, setDisplayedRecordingError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'uploads' | 'recordings'>('uploads');

    const includeResearchMeeting = useIncludeUserResearchInterviewInMeetingTypeSelection();
    const includeGeneralSalesMeeting = user.isEnabledForGeneralSalesMeetingType;
    const includeCustomerSuccessCheckIn = user.isEnabledForCustomerSuccessCheckInMeetingType;
    const includeCompanyAllHandsMeeting = user.isEnabledForCompanyAllHandsMeetingType;

    // Initialize with user's default language settings or English as fallback
    const [spokenLanguage, setSpokenLanguage] = useState<string>(
        languageCodeMap[user?.metadata.defaultMeetingLanguage || ENGLISH_LANGUAGE]
    );
    const [translateToLanguage, setTranslateToLanguage] = useState<string>(
        languageCodeMap[user?.metadata.defaultOutputLanguage || ENGLISH_LANGUAGE]
    );
    const [meetingType, setMeetingType] = useState<string>(ScribeMeetingType.Generic);
    const [saveAsDraft, setSaveAsDraft] = useState<boolean>(
        user.metadata.isEditingAiEmailsByDefault || user.isForcedIntoEditFlow
    );

    const isMeetingTypeEditingSupported = isMeetingTypeEnabledForEditing(meetingType as ScribeMeetingType);

    const [templates] = useGlobalCustomTemplates();
    const [, setOpenModal] = useGlobalModal();
    const [selectedCustomTemplateId, setSelectedCustomTemplateId] = useState<string | undefined>();
    const hasCustomTemplates = templates && templates?.length > 0;

    // display the recording error if it exists but clear it from the global dashboard state after it's displayed
    useEffect(() => {
        if (!!recordingError && !displayedRecordingError) {
            setDisplayedRecordingError(recordingError);
            setGlobalAiDashboardState((state) => ({ ...state, recordingError: undefined }));
        }
    }, [recordingError, displayedRecordingError, setGlobalAiDashboardState]);

    useEffect(() => {
        (async () => {
            if (audioRecordingBlob) {
                // YYYY-MM-DD_HH-MM-SS_AudioRecording.webm
                const timestamp = new Date().toISOString().replace(/T/, '_').replace(/\..+/, '').replace(/:/g, '-');
                const fileExtension = audioRecordingMimeType?.includes('mp4') ? 'mp4' : 'webm';
                const fileName = `${timestamp}_AudioRecording.${fileExtension}`;
                const file = new File([audioRecordingBlob], fileName, {
                    type: audioRecordingMimeType,
                });
                if (file) {
                    track(ClientEventType.AIDashboardActivity, {
                        Activity: 'Recording Selected',
                    });
                    setSelectedFile(file);
                    setSelectedFileUrl(URL.createObjectURL(file));
                    setShowDownload(true);

                    if (
                        user.isEnabledForAutoDownloadRecordingOnStop &&
                        !user.isEnabledForLocalRecordingTabInUploadSection // no need to autodownload if the user has the local recording tab enabled
                    ) {
                        const downloadUrl = URL.createObjectURL(file);
                        const a = document.createElement('a');
                        a.href = downloadUrl;
                        a.download = fileName;
                        a.click();
                    }
                }
                // clear the audio recording blob
                setGlobalAiDashboardState((state) => ({ ...state, audioRecordingBlob: undefined }));
            }
        })();
    }, [
        audioRecordingBlob,
        setGlobalAiDashboardState,
        user.isEnabledForAutoDownloadRecordingOnStop,
        user.isEnabledForLocalRecordingTabInUploadSection,
    ]);

    useEffect(() => {
        if (meetingType === ScribeMeetingType.Custom && hasCustomTemplates && templates) {
            setSelectedCustomTemplateId(templates[0]._id);
        }
    }, [templates, meetingType, hasCustomTemplates]);

    useEffect(() => {
        if (!isMeetingTypeEditingSupported) {
            setSaveAsDraft(false);
        }
    }, [isMeetingTypeEditingSupported]);

    const languageOptions = MEETING_LANGAUGES.filter((lang) => lang !== MEETING_LANGUAGE_SEPARATOR).map((lang) => ({
        label: lang,
        code: languageCodeMap[lang],
        disabled: false,
    }));

    const outputLanguageOptions = OUTPUT_LANGUAGES.filter((lang) => lang !== MEETING_LANGUAGE_SEPARATOR).map(
        (lang) => ({
            label: lang,
            code: languageCodeMap[lang],
            disabled: false,
        })
    );

    const meetingTemplateOptions: ListItemValue<ScribeMeetingType, string>[] = Object.entries(ScribeMeetingType)
        .map(([key, value]) => ({
            code: value,
            label: key
                .split(/(?=[A-Z])/)
                .join(' ')
                .toLowerCase()
                .replace(/\b\w/g, (c) => c.toUpperCase()),
            disabled: false,
        }))
        .filter((option) => option.code !== ScribeMeetingType.Custom);

    // Add custom template option if enabled
    if (user.isEnabledForCustomSummaryTemplates) {
        const customTemplateOption: ListItemValue<ScribeMeetingType, string> = {
            code: ScribeMeetingType.Custom,
            label: !hasCustomTemplates ? 'Create custom template' : 'Custom template',
            isTooltipInteractive: true,
            disabled: false,
            customLabel: (
                <Row
                    style={{
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <span
                        style={{
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            textAlign: 'left',
                            alignItems: 'center',
                            textDecoration: 'none',
                            minHeight: 'auto',
                            width: '100%',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            fontSize: '1rem',
                            boxSizing: 'border-box',
                            fontWeight: 400,
                            lineHeight: '1.5',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {!hasCustomTemplates ? 'Create custom template' : 'Custom template'}
                    </span>
                </Row>
            ),
            onClick: !hasCustomTemplates
                ? () => {
                      track(ClientEventType.AIDashboardClick, {
                          ClickedOn: 'Create Custom Template',
                          From: 'upload_section',
                      });
                      setOpenModal(GlobalModal.CreateCustomSummaryTemplate);
                      return true;
                  }
                : undefined,
        };

        meetingTemplateOptions.unshift(customTemplateOption);
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoadingUploads(true);
                setUserUploads((await getSpinachAPI<FileUpload[]>(SpinachAPIPath.UserUploads)) ?? []);
            } catch (error) {
                setToastText('Failed to fetch uploads');
            } finally {
                setIsLoadingUploads(false);
            }
        })();
    }, [setToastText, user?.spinachUserId]);

    useEffect(() => {
        (async () => {
            try {
                const usage = await getSpinachAPI<UploadUsage>(SpinachAPIPath.UserUploadUsage);
                setUploadUsage(usage ?? null);
            } catch (error) {
                setToastText('Failed to fetch upload usage');
            }
        })();
    }, [setToastText]);

    const handleUploadClick = (upload: FileUpload) => {
        if (upload.status === FileUploadStatus.Completed) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'View Completed Upload',
            });
            openBotIdFromUploads(`upload-${upload._id}`);
        }
    };

    const uploadFileToS3 = async (file: File, signedUrlResponse: FileUploadResponse) => {
        const { s3PostResponse } = signedUrlResponse;
        if (!s3PostResponse) {
            throw new Error('Missing S3 post response');
        }

        const formData = new FormData();
        Object.entries(s3PostResponse.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append('file', file);

        const xhr = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setUploadProgress(progress);
                }
            });

            xhr.addEventListener('load', () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Upload failed: ${xhr.status} ${xhr.statusText}`));
                }
            });

            xhr.addEventListener('error', () => {
                reject(new Error('Upload failed'));
            });

            xhr.open('POST', s3PostResponse.url);

            // if (s3PostResponse.fields['x-amz-tagging']) {
            //     xhr.setRequestHeader('x-amz-tagging', decodeURIComponent(s3PostResponse.fields['x-amz-tagging']));
            // }
            // if (s3PostResponse.fields['Content-Disposition']) {
            //     xhr.setRequestHeader('Content-Disposition', s3PostResponse.fields['Content-Disposition']);
            // }

            xhr.send(formData);
        });
    };

    const resetUpload = () => {
        track(ClientEventType.AIDashboardActivity, {
            Activity: isUploading ? 'Upload Cancelled' : 'Upload Discarded',
            Progress: uploadProgress,
        });
        setSelectedFile(null);
        setSelectedFileUrl(null);
        setShowDownload(false);
        setIsUploading(false);
        setUploadProgress(0);
        setParticipantNames(user?.preferredName ? [{ id: uuid(), text: user.preferredName }] : []);
        setPendingParticipant('');
        setInputActive(false);
        isSubmittedRef.current = false;
        setIsSubmitted(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!handleFileSelectAttempt()) {
            event.preventDefault();
            return;
        }
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Upload Selected',
        });
        setSelectedFile(file);
        setSelectedFileUrl(URL.createObjectURL(file));
        initFullstory();
    };

    const handleFileSelectAttempt = () => {
        if (!uploadUsage) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Upload Attempt',
                Status: 'No Usage Data',
            });
            return true;
        }

        if (uploadUsage.currentUploads >= uploadUsage.maxUploadsPerWindow) {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Upload Attempt',
                Status: 'Limit Reached',
                CurrentUploads: uploadUsage.currentUploads,
                MaxUploads: uploadUsage.maxUploadsPerWindow,
            });
            setIsLimitReached(true);
            setShowUploadLimitModal(true);
            return false;
        }

        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Upload Attempt',
            Status: 'Success',
        });
        setIsLimitReached(false);
        setShowUploadLimitModal(false);
        return true;
    };

    const handleUpgradeClick = () => {
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Upgrade Click',
            Source: 'Upload Limit Modal',
        });
        navigateToUpgrade('Upload Limit Modal');
        setShowUploadLimitModal(false);
    };

    const handleLearnMoreClick = () => {
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Learn More Click',
            Source: 'Upload Limit Modal',
        });
        window.open('https://help.spinach.ai/en/articles/10375173-upload-recordings', '_blank');
        setShowUploadLimitModal(false);
    };

    const startUpload = async (file: File) => {
        setIsUploading(true);
        setUploadProgress(0);

        try {
            const signedUrlResponse = await postSpinachAPI<FileUploadResponse>(
                SpinachAPIPath.NewUploadFile,
                {
                    contentType: file.type,
                    contentLength: file.size,
                },
                {
                    throwOnError: true,
                }
            );

            if (!signedUrlResponse?.s3PostResponse) {
                throw new Error('Failed to get upload URL');
            }

            await uploadFileToS3(file, signedUrlResponse);
            const uploadId = signedUrlResponse.fileUploadId;

            if (isSubmittedRef.current) {
                await handleCompleteUpload(uploadId);
            }
            setIsUploading(false);
        } catch (error) {
            handleUploadError(error);
            resetUpload();
        }
    };

    const handleCompleteUpload = async (uploadId: string) => {
        try {
            await postSpinachAPI(SpinachAPIPath.CompleteUploadFile, {
                fileUploadId: uploadId,
                customTemplateId: meetingType === ScribeMeetingType.Custom ? selectedCustomTemplateId : null,
                participantNames: participantNames.map((p) => p.text),
                fileName: selectedFile?.name ?? 'Unknown file',
                spokenLanguage: spokenLanguage,
                translateToLanguage: translateToLanguage,
                meetingType: meetingType,
                isHostEditing: saveAsDraft,
            });
            setToastText('File uploaded successfully');
            resetUpload();

            // Refetch both uploads and usage data
            setUserUploads((await getSpinachAPI<FileUpload[]>(SpinachAPIPath.UserUploads)) ?? []);
            const usage = await getSpinachAPI<UploadUsage>(SpinachAPIPath.UserUploadUsage);
            setUploadUsage(usage ?? null);

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Upload Completed',
                ParticipantCount: participantNames.length,
                FileSize: selectedFile?.size,
                FileName: selectedFile?.name,
            });
        } catch (error) {
            handleUploadError(error);
        }
    };

    const handleSubmit = async () => {
        if (!selectedFile) {
            return;
        }

        isSubmittedRef.current = true;
        setIsSubmitted(true);
        await startUpload(selectedFile);
    };

    const handleUploadError = (error: unknown) => {
        Sentry.captureException(error);
        let specificErrorMessage = '';
        if (axios.isAxiosError(error)) {
            specificErrorMessage = error.response?.data.message || 'Failed to upload file';
        } else {
            specificErrorMessage = 'Failed to upload file';
        }
        setToastText(specificErrorMessage);
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Upload Error',
            Error: specificErrorMessage,
            FileSize: selectedFile?.size,
            FileName: selectedFile?.name,
        });
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = async (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!handleFileSelectAttempt()) {
            return;
        }

        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setToastText('Please upload only one file at a time');
            return;
        }

        const file = files[0];
        if (file) {
            const fakeEvent = {
                target: {
                    files: [file],
                },
            } as unknown as ChangeEvent<HTMLInputElement>;
            await handleFileChange(fakeEvent);
        }
    };

    const handleParticipantSave = () => {
        if (pendingParticipant.trim().length === 0) {
            return;
        }
        setParticipantNames((prev) => [...prev, { id: uuid(), text: pendingParticipant.trim() }]);
        setPendingParticipant('');
        setInputActive(false);
    };

    const deleteParticipant = (id: string) => {
        setParticipantNames((prev) => prev.filter((p) => p.id !== id));
    };

    const handleParticipantKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Tab' || e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            handleParticipantSave();
            setPendingParticipant('');
            setInputActive(true);
            setTimeout(() => {
                participantInputRef.current?.focus();
            }, 100);
        } else if (e.key === 'Backspace' && pendingParticipant === '' && participantNames.length > 0) {
            e.preventDefault();
            // Get the last participant
            const lastParticipant = participantNames[participantNames.length - 1];
            // Remove it from the list
            setParticipantNames((prev) => prev.slice(0, -1));
            // Set it as the pending participant
            setPendingParticipant(lastParticipant.text);
            setInputActive(true);
            // Focus the input
            setTimeout(() => {
                participantInputRef.current?.focus();
            }, 100);
        }
    };

    useInterval(async () => {
        const hasInProgressUploadsFromLast24Hours = userUploads.some((upload) => {
            const isProcessingOrTranscribing =
                upload.status === FileUploadStatus.Transcribing || upload.status === FileUploadStatus.Processing;

            const isFromLast24Hours =
                new Date(upload.createdAt).getTime() > new Date(Date.now() - 1000 * 60 * 60 * 24).getTime();

            return isProcessingOrTranscribing && isFromLast24Hours;
        });

        if (hasInProgressUploadsFromLast24Hours) {
            setUserUploads((await getSpinachAPI<FileUpload[]>(SpinachAPIPath.UserUploads)) ?? []);
        }
    }, 10000);

    const renderParticipantInput = () => (
        <Box className={classes.participantInput}>
            <Box className={classes.labelSection}>
                <BodyRegularOnboard>
                    <b>Speaker names:</b>
                </BodyRegularOnboard>
                <BootstrapTooltip
                    tooltipStyle={{
                        maxWidth: '350px',
                    }}
                    placement="top"
                    title={
                        <>
                            <BodyRegularOnboard style={{ color: 'white' }}>
                                Names are used to identify participants in the summaries and transcript.
                            </BodyRegularOnboard>
                            <BodyRegularOnboard style={{ color: 'white' }}>
                                Unnamed participants will be refered to as Speaker A, Speaker B, etc.
                            </BodyRegularOnboard>
                        </>
                    }
                >
                    <InfoOutlined htmlColor={lightTheme.primary.greenLight} style={{ marginLeft: '8px' }} />
                </BootstrapTooltip>
            </Box>

            <Box className={classes.tagsContainer}>
                {participantNames.map((participant) => (
                    <DeletableTag
                        key={participant.id}
                        id={participant.id}
                        text={participant.text}
                        deleteItem={deleteParticipant}
                        disabled={isSubmitted}
                        contentMasking={true}
                    />
                ))}
                {!isSubmitted ? (
                    <NewTag
                        inputRef={participantInputRef}
                        onBlur={handleParticipantSave}
                        handleKeyDown={handleParticipantKeyDown}
                        pendingText={pendingParticipant}
                        setPendingText={setPendingParticipant}
                        inputActive={inputActive}
                        placeholder="Add participant"
                        editNew={() => {
                            setPendingParticipant('');
                            setInputActive(true);
                            setTimeout(() => {
                                participantInputRef.current?.focus();
                            }, 100);
                        }}
                        isDisabled={isSubmitted}
                    />
                ) : null}
            </Box>
        </Box>
    );

    const renderUploadLimitModal = () => (
        <Modal open={showUploadLimitModal} onClose={() => setShowUploadLimitModal(false)}>
            <SpinachModalContent onClose={() => setShowUploadLimitModal(false)}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>
                            {isLimitReached ? 'Upload limit reached' : 'Your upload limit'}
                        </ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <BodyRegularOnboard style={{ textAlign: 'center' }}>
                        {isLimitReached
                            ? `You've reached your daily upload limit of ${uploadUsage?.maxUploadsPerWindow} files. This will reset tomorrow. Upgrade for unlimited.`
                            : `You uploaded ${uploadUsage?.currentUploads} files today. Your user is eligible for ${uploadUsage?.maxUploadsPerWindow} uploads per day.`}
                    </BodyRegularOnboard>
                    <Spacing />
                    <PrimaryButton
                        title="Upgrade plan"
                        onClick={handleUpgradeClick}
                        containerStyles={{ width: '100%' }}
                    />
                    <Spacing factor={1 / 2} />
                    <BodyRegularOnboard className={classes.learnMoreText} onClick={handleLearnMoreClick}>
                        Learn more
                    </BodyRegularOnboard>
                </Column>
            </SpinachModalContent>
        </Modal>
    );

    const UploadLimitDisplay = ({ usage }: { usage: UploadUsage }) => {
        if (usage.type === CheckoutSessionPlanType.PayAsYouGo) {
            return null;
        }

        return (
            <Row style={{ width: '100%', alignItems: 'center', marginTop: '7px' }}>
                <BodyRegularOnboard>
                    <b>{`${usage.maxUploadsPerWindow - usage.currentUploads}/${
                        usage.maxUploadsPerWindow
                    } uploads available (per day)`}</b>
                </BodyRegularOnboard>
                <Badge title="Upgrade" onClick={() => setShowUploadLimitModal(true)} />
            </Row>
        );
    };

    const renderLanguageSelections = () => (
        <Box className={classes.participantInput} style={{ marginTop: 0 }}>
            <Box className={classes.languageSelectionsContainer}>
                <Box className={classes.languageRow}>
                    <Box className={classes.languageLabel}>
                        <BodyRegularOnboard style={{ display: 'flex', alignItems: 'center' }}>
                            <b>AI Summary Template:</b>
                        </BodyRegularOnboard>
                        <BootstrapTooltip
                            tooltipStyle={{
                                maxWidth: '350px',
                            }}
                            placement="top"
                            title={
                                <BodyRegularOnboard style={{ color: 'white' }}>
                                    {meetingType === ScribeMeetingType.Custom && hasCustomTemplates
                                        ? 'Using your custom template for this summary'
                                        : 'Select a template that best matches your recording for better summaries'}
                                </BodyRegularOnboard>
                            }
                        >
                            <InfoOutlined htmlColor={lightTheme.primary.greenLight} />
                        </BootstrapTooltip>
                    </Box>
                    <Box className={classes.dropdownContainer}>
                        <Box className={meetingType ? classes.dropdownWrapper : undefined}>
                            <MeetingTypeSelector
                                selectedMeetingType={meetingType as ScribeMeetingType}
                                onMeetingTypeChange={(selectedType, customTemplateId) => {
                                    setMeetingType(selectedType);
                                    setSelectedCustomTemplateId(customTemplateId);
                                    track(ClientEventType.AIDashboardActivity, {
                                        Activity: 'Meeting Template Selected',
                                    });
                                }}
                                isDisabled={isSubmitted}
                                includeResearchMeeting={includeResearchMeeting}
                                includeGeneralSalesMeeting={includeGeneralSalesMeeting}
                                includeCustomerSuccessCheckIn={includeCustomerSuccessCheckIn}
                                includeCompanyAllHandsMeeting={includeCompanyAllHandsMeeting}
                                isEnabledForCustomSummaryTemplates={user.isEnabledForCustomSummaryTemplates}
                                from="upload_section"
                            />
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.languageRow}>
                    <Box className={classes.languageLabel}>
                        <BodyRegularOnboard>
                            <b>Language spoken:</b>
                        </BodyRegularOnboard>
                        <BootstrapTooltip
                            tooltipStyle={{
                                maxWidth: '350px',
                            }}
                            placement="top"
                            title={
                                <BodyRegularOnboard style={{ color: 'white' }}>
                                    Select the primary language spoken in the recording. You can set your default
                                    speaking language in Settings.
                                </BodyRegularOnboard>
                            }
                        >
                            <InfoOutlined htmlColor={lightTheme.primary.greenLight} />
                        </BootstrapTooltip>
                    </Box>
                    <Box className={classes.dropdownContainer}>
                        <Box className={spokenLanguage ? classes.dropdownWrapper : undefined}>
                            <DropDown
                                title="Select Recording Language"
                                values={languageOptions}
                                selected={spokenLanguage}
                                handleSelection={(label) => {
                                    setSpokenLanguage(label);
                                    track(ClientEventType.AIDashboardActivity, {
                                        Activity: 'Recording Language Selected',
                                        Language: languageCodeMap[label],
                                    });
                                }}
                                disabled={isSubmitted}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.languageRow}>
                    <Box className={classes.languageLabel}>
                        <BodyRegularOnboard>
                            <b>Translate to:</b>
                        </BodyRegularOnboard>
                        <BootstrapTooltip
                            tooltipStyle={{
                                maxWidth: '350px',
                            }}
                            placement="top"
                            title={
                                <BodyRegularOnboard style={{ color: 'white' }}>
                                    Select the desired language for the transcript and summary output. You can set your
                                    default output language in Settings.
                                </BodyRegularOnboard>
                            }
                        >
                            <InfoOutlined htmlColor={lightTheme.primary.greenLight} />
                        </BootstrapTooltip>
                    </Box>
                    <Box className={classes.dropdownContainer}>
                        <Box className={translateToLanguage ? classes.dropdownWrapper : undefined}>
                            <DropDown
                                title="Select Output Language"
                                values={outputLanguageOptions}
                                selected={translateToLanguage}
                                handleSelection={(label) => {
                                    setTranslateToLanguage(label);
                                    track(ClientEventType.AIDashboardActivity, {
                                        Activity: 'Output Language Selected',
                                        Language: languageCodeMap[label],
                                    });
                                }}
                                disabled={isSubmitted}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.languageRow} style={{ minHeight: '42px' }}>
                    <Box className={classes.languageLabel}>
                        <BodyRegularOnboard>
                            <b>Edit Summary:</b>
                        </BodyRegularOnboard>
                        <BootstrapTooltip
                            tooltipStyle={{
                                maxWidth: '350px',
                            }}
                            placement="top"
                            title={
                                <BodyRegularOnboard style={{ color: 'white' }}>
                                    {!isMeetingTypeEditingSupported
                                        ? 'This meeting template does not support draft editing.'
                                        : 'Summary will generate as a draft for you to edit and select who to send to. When toggled off, share generated summary via link.'}
                                </BodyRegularOnboard>
                            }
                        >
                            <InfoOutlined htmlColor={lightTheme.primary.greenLight} />
                        </BootstrapTooltip>
                    </Box>
                    <Box className={classes.dropdownContainer}>
                        <Box className={classes.switchContainer}>
                            <SpinachSwitch
                                checked={saveAsDraft}
                                disabled={
                                    !user.hasProFeatures ||
                                    user.isForcedIntoEditFlow ||
                                    isSubmitted ||
                                    !isMeetingTypeEditingSupported
                                }
                                onChange={() => {
                                    if (user.isForcedIntoEditFlow) {
                                        return;
                                    }
                                    setSaveAsDraft(!saveAsDraft);
                                    track(ClientEventType.AIDashboardActivity, {
                                        Activity: 'Save As Draft Selected',
                                        Value: !saveAsDraft,
                                    });
                                }}
                            />
                            <BodyRegularOnboard className={classes.switchText}>
                                Let me edit and send to recipients
                            </BodyRegularOnboard>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box className={classes.uploadSectionContainer}>
            {renderUploadLimitModal()}
            <Spacing factor={1 / 2} />
            <HeaderThreeOnboard style={{ textAlign: 'left' }}>Upload Recording</HeaderThreeOnboard>
            <Row vCenter style={{ marginTop: '10px' }}>
                <BodyRegularOnboard>Automatically convert recordings to notes and transcripts.</BodyRegularOnboard>
            </Row>
            {uploadUsage ? <UploadLimitDisplay usage={uploadUsage} /> : null}
            <Spacing factor={1 / 2} />
            <Spacing factor={1 / 2} />
            {displayedRecordingError ? (
                <>
                    <Row
                        vCenter
                        centered
                        style={{
                            marginBottom: '10px',
                            background: '#FCF5E9',
                            padding: '10px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <InfoOutlined htmlColor={lightTheme.status.negative} />
                        <BodyRegularOnboard style={{ color: lightTheme.status.negative, marginLeft: '10px' }}>
                            {displayedRecordingError}
                        </BodyRegularOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />
                </>
            ) : null}
            {!selectedFile ? (
                <div className={classes.dropZone} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <CloudUploadIcon className={classes.uploadIcon} />
                    <BodyRegularOnboard>
                        <b>Drag and drop or select a recording file to upload</b>
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 2} />
                    <OutlinedButton
                        title="Select file"
                        onClick={(e) => {
                            if (!handleFileSelectAttempt()) {
                                return;
                            }
                            fileInputRef.current?.click();
                        }}
                        size={ButtonSize.Normal}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        accept={supportedUploadFileTypes.join(',')}
                        multiple={false}
                    />
                </div>
            ) : (
                <div className={classes.selectedFileContainer}>
                    <div className={classes.uploadContainer}>
                        <div className={classes.fileDisplay}>
                            {!isUploading && selectedFileUrl ? (
                                <>
                                    <video
                                        src={selectedFileUrl}
                                        style={{ width: '200px', maxHeight: '150px', marginBottom: '20px' }}
                                        controls
                                    />
                                    {showDownload ? (
                                        <>
                                            <a href={selectedFileUrl} download={selectedFile.name}>
                                                <Row centered vCenter>
                                                    <DownloadIcon
                                                        style={{
                                                            marginRight: '12px',
                                                            color: lightTheme.secondary.midnight,
                                                        }}
                                                    />
                                                    <span>Download</span>
                                                </Row>
                                            </a>
                                            <Spacing factor={1 / 2} />
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                            <div className={classes.fileInfo}>
                                <span role="img" aria-label="video" className={classes.fileIcon}>
                                    🎥
                                </span>
                                <div {...withContentMasking(classes.fileNameWrapper)}>
                                    <BodyRegularOnboard className={classes.fileNameText}>
                                        {selectedFile.name}
                                    </BodyRegularOnboard>
                                </div>
                                <BodyRegularOnboard className={classes.fileSize} style={{ flexShrink: 0 }}>
                                    {Math.round(selectedFile.size / (1024 * 1024))} MB
                                </BodyRegularOnboard>
                            </div>
                        </div>
                    </div>
                    {isUploading ? (
                        <div className={classes.progressContainer}>
                            <LinearProgress
                                variant="determinate"
                                value={uploadProgress}
                                style={{ marginBottom: '8px' }}
                            />

                            <BodyRegularOnboard>{uploadProgress}%</BodyRegularOnboard>
                        </div>
                    ) : null}

                    <Box className={classes.formSectionsContainer}>
                        {renderParticipantInput()}
                        <Spacing factor={1 / 2} />
                        {renderLanguageSelections()}
                    </Box>

                    <Box mt={2} display="flex" style={{ gap: '20px' }} flexDirection="column" alignItems="center">
                        <PrimaryButton
                            title={isUploading ? 'Uploading...' : 'Upload'}
                            onClick={handleSubmit}
                            disabled={!selectedFile || isUploading}
                            containerStyles={{ minWidth: '200px' }}
                        />
                        <SecondaryButton title={isUploading ? 'Cancel' : 'Discard'} onClick={resetUpload} />
                    </Box>
                </div>
            )}
            <Spacing factor={1 / 2} />
            {user.isEnabledForSidebarRecorder && user.isEnabledForLocalRecordingTabInUploadSection ? (
                <TabContainer>
                    <TabHeader
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Upload History Tab',
                            });
                            setActiveTab('uploads');
                        }}
                    >
                        <TabHeaderText isSelected={activeTab === 'uploads'}>Upload History</TabHeaderText>
                        {isLoadingUploads ? (
                            <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
                                <CircularProgress size={24} style={{ color: lightTheme.neutrals.midnight }} />
                            </Box>
                        ) : null}
                    </TabHeader>
                    <TabHeader
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Draft Recordings Tab',
                            });
                            setActiveTab('recordings');
                        }}
                    >
                        <TabHeaderText isSelected={activeTab === 'recordings'}>Draft Recordings</TabHeaderText>
                    </TabHeader>
                </TabContainer>
            ) : (
                <Row>
                    <HeaderThree>Upload History</HeaderThree>
                    {isLoadingUploads ? (
                        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
                            <CircularProgress size={24} style={{ color: lightTheme.neutrals.midnight }} />
                        </Box>
                    ) : null}
                </Row>
            )}
            <Spacing factor={1 / 3} />

            {activeTab === 'uploads' ? (
                <UploadHistoryTab
                    userUploads={userUploads}
                    isLoadingUploads={isLoadingUploads}
                    handleUploadClick={handleUploadClick}
                    classes={classes}
                />
            ) : (
                <LocalRecordingsTab />
            )}
        </Box>
    );
}
