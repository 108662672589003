import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { FileCopyOutlined, InfoOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    BootstrapTooltip,
    ButtonSize,
    HeaderThree,
    Spacing,
    copyTextToClipboard,
    lightTheme,
    useClickTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    withContentMasking,
} from '../../../../..';
import { useGlobalMcpServerUrlKey } from '../../../../hooks/useGlobalMcpServerUrlKey';
import { Row } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { UseHoverRowProps } from '../types';

export function McpIntegrationSection({ hoverRowProps }: { hoverRowProps: UseHoverRowProps }): JSX.Element {
    const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);
    const { getHoverableRowProps } = hoverRowProps;
    const clickTracking = useClickTracking();
    const [user] = useGlobalAuthedUser();
    const [shouldFetchMcpURL, setShouldFetchMcpURL] = useState(false);
    const { data: mcpURL } = useGlobalMcpServerUrlKey(shouldFetchMcpURL);
    const [isLoading, setIsLoading] = useState(false);
    const [showUrl, setShowUrl] = useState(false);
    const { setToastText } = useGlobalAiDashboard();
    if (!user.isMcpIntegrationEnabled) {
        return <></>;
    }
    const handleClickShowUrl = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Toggle Show MCP URL Key', {
            IsHidden: showUrl,
        });
        setShowUrl((show) => !show);
    };

    return (
        <>
            <Row>
                <HeaderThree>MCP Server</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    Accelerate your workflow with context from your meetings (
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: isLearnMoreHovered ? '#0068b1' : lightTheme.primary.midnight,
                        }}
                        onMouseEnter={() => {
                            setIsLearnMoreHovered(true);
                        }}
                        onMouseLeave={() => setIsLearnMoreHovered(false)}
                        onClick={() => window.Intercom?.('showArticle', '9905914')}
                    >
                        learn more
                    </span>
                    )
                </BodyRegularOnboard>
            </Row>

            <Spacing factor={1 / 2} />

            <Row vCenter>
                <Row style={{ width: 'unset' }}>
                    <BootstrapTooltip
                        interactive
                        tooltipStyle={{
                            maxWidth: '350px',
                            backgroundColor: lightTheme.neutrals.white,
                            border: `1px solid ${lightTheme.neutrals.grayDark}`,
                            borderRadius: '6px',
                        }}
                        arrowStyle={{
                            backgroundColor: lightTheme.neutrals.white,
                            border: `1px solid ${lightTheme.neutrals.grayDark}`,
                        }}
                        placement="top"
                        title={
                            <>
                                <BodyRegularOnboard>
                                    Use the Spinach MCP Server to ask questions about your meetings, generate PRDs and
                                    more
                                </BodyRegularOnboard>
                            </>
                        }
                    >
                        <InfoOutlined htmlColor={lightTheme.primary.greenLight} style={{ marginRight: '10px' }} />
                    </BootstrapTooltip>
                    <Spacing horizontal factor={1 / 3} />
                </Row>

                {mcpURL ? (
                    <Row vCenter style={{ width: 'unset' }}>
                        <BodyRegularOnboard>MCP Server URL: </BodyRegularOnboard>
                        <Spacing horizontal factor={1 / 3} />

                        <OutlinedInput
                            fullWidth={false}
                            id="outlined-adornment-password"
                            type={showUrl ? 'text' : 'password'}
                            value={mcpURL}
                            {...withContentMasking()}
                            disabled={true}
                            multiline={false}
                            endAdornment={
                                <>
                                    <InputAdornment position="end">
                                        <BootstrapTooltip title="Reveal URL">
                                            <IconButton
                                                aria-label="toggle URL visibility"
                                                onClick={handleClickShowUrl}
                                                edge="end"
                                            >
                                                {showUrl ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </BootstrapTooltip>
                                    </InputAdornment>
                                    <InputAdornment position="end">
                                        <BootstrapTooltip title="Copy" placement="top">
                                            <IconButton
                                                aria-label="copy api key"
                                                onClick={() => {
                                                    clickTracking(ClientEventType.AIDashboardClick, 'Copy MCP URL');
                                                    copyTextToClipboard(mcpURL);
                                                    setToastText('Copied!');
                                                }}
                                                edge="end"
                                            >
                                                <FileCopyOutlined />
                                            </IconButton>
                                        </BootstrapTooltip>
                                    </InputAdornment>
                                </>
                            }
                            label="Password"
                        />
                    </Row>
                ) : (
                    <Row vCenter style={{ width: 'unset' }}>
                        <BodyRegularOnboard>Connect to MCP Server</BodyRegularOnboard>
                        <Spacing horizontal factor={1 / 3} />
                        <OutlinedButton
                            title="Display URL"
                            isLoading={isLoading}
                            size={ButtonSize.Small}
                            onClick={async () => {
                                setIsLoading(true);
                                clickTracking(ClientEventType.AIDashboardClick, 'Get MCP URL');
                                setShouldFetchMcpURL(true);
                            }}
                        />
                    </Row>
                )}
            </Row>
        </>
    );
}
