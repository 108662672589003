import { Modal } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, CustomSummaryTemplateData, CustomSummaryTemplateSection } from '@spinach-shared/types';

import { postCreateCustomSummaryTemplate } from '../../../apis/postCreateCustomSummaryTemplate';
import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import { useExperienceTracking, useGlobalCustomTemplates, useGlobalModal, useGlobalModalState } from '../../../hooks';
import { BodyRegular, BodyRegularOnboard, HeaderThree, lightTheme } from '../../../styles';
import { withContentMasking } from '../../../utils/withContentMasking';
import { PrimaryButton } from '../../stand-up/PrimaryButton';
import { BootstrapTooltip } from '../BootstrapTooltip';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row } from '../framing';

const DEFAULT_SECTION: CustomSummaryTemplateSection = {
    key: '',
    title: '',
    description: '',
    format: 'bullets',
};

const INITIAL_SECTIONS = [
    {
        ...DEFAULT_SECTION,
        key: 'keyTopics',
        title: 'Key Topics',
        description:
            'Detailed descriptions highlighting the primary subjects, discussions, and significant points covered during the call. Identify core themes, essential issues deliberated, and broad topics that shaped the meeting agenda, clearly indicating their importance and relevance.',
    },
    {
        ...DEFAULT_SECTION,
        key: 'actionItems',
        title: 'Action Items',
        description:
            'Clear specifications of tasks or responsibilities assigned to specific team members, extracted directly from the conversation. Mention concrete actions agreed upon in the call, including deadlines, expected results, and references to usernames responsible for fulfilling each action item.',
    },
    {
        ...DEFAULT_SECTION,
        key: 'keyDecisions',
        title: 'Key Decisions',
        description:
            'Descriptions of critical decisions or agreements clearly reached during the meeting. Detail essential conclusions and collective agreements, the rationale for each decision if provided, and any notable impacts these decisions have on work or processes.',
    },
    {
        ...DEFAULT_SECTION,
        key: 'blockers',
        title: 'Blockers',
        description:
            'Document any obstacles, impediments, or unresolved challenges noted by participants that could hinder progress. Detail who mentioned each blocker if applicable (using usernames provided), any implications discussed, and context surrounding the impediments as stated explicitly in the call.',
    },
    {
        ...DEFAULT_SECTION,
        key: 'nextSteps',
        title: 'Next Steps',
        description:
            'Clearly outline follow-up tasks, events, or plans agreed upon at the conclusion or identified during the call. This includes planned activities, future meetings, checkpoints or reviews, clearly indicating who (using provided usernames) is responsible or involved and associated timelines if specified.',
    },
];

const SectionContainer = styled.div<{ isContentEmpty: boolean; hasContentError: boolean }>`
    position: relative;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: ${lightTheme.neutrals.white};
    border-left: ${(props) =>
        props.isContentEmpty
            ? `4px solid ${lightTheme.neutrals.gray}`
            : props.hasContentError
            ? `4px solid ${lightTheme.status.negative}`
            : `4px solid ${lightTheme.primary.greenLight}`};
`;

const InfoContainer = styled(Row)`
    align-items: flex-start;
    background-color: ${lightTheme.neutrals.grayLight};
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid ${lightTheme.neutrals.gray};
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    &:focus {
        outline: none;
        border-color: ${lightTheme.primary.greenLight};
    }
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid ${lightTheme.neutrals.gray};
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    overflow: hidden;
    min-height: 70px;
    &:focus {
        outline: none;
        border-color: ${lightTheme.primary.greenLight};
    }
`;

const ValidationMessage = styled.div<{ type: 'error' | 'info' }>`
    font-size: 12px;
    color: ${(props) => (props.type === 'error' ? lightTheme.status.negative : lightTheme.neutrals.grayDark)};
    margin-top: 4px;
`;

export const CustomSummaryTemplateModal = () => {
    const [_, setOpenModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [templates, setGlobalTemplates] = useGlobalCustomTemplates();
    const { metadata, modal } = useGlobalModalState();

    const editingTemplateId =
        metadata?.metadataType === GlobalModalMetadataType.CreateCustomSummaryTemplate ? metadata.templateId : null;

    const editingTemplate = editingTemplateId ? templates?.find((t) => t._id === editingTemplateId) : null;

    const [sections, setSections] = useState<CustomSummaryTemplateSection[]>(
        editingTemplate ? editingTemplate.sections : INITIAL_SECTIONS
    );

    const templateName = editingTemplate?.name || 'Custom Template';
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [originalSections, setOriginalSections] = useState<CustomSummaryTemplateSection[]>(
        editingTemplate ? editingTemplate.sections : INITIAL_SECTIONS
    );

    useEffect(() => {
        if (editingTemplate?.sections) {
            setSections(editingTemplate.sections);
            setOriginalSections(editingTemplate.sections);
        }
    }, [editingTemplate]);

    const handleClose = () => {
        setOpenModal(null);
    };

    const handleSectionChange = (index: number, field: keyof CustomSummaryTemplateSection, value: string) => {
        const newSections = [...sections];
        newSections[index] = { ...newSections[index], [field]: value };

        if (field === 'title' && (!newSections[index].key || newSections[index].key.startsWith('section'))) {
            newSections[index].key = value
                .toLowerCase()
                .replace(/[^a-z0-9]/g, '')
                .substring(0, 20);
        }

        setSections(newSections);
    };

    const handleTextAreaHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const hasValidationErrors = () => {
        // Check for sections with validation errors
        return sections.some((section) => {
            // Skip empty sections as they'll be removed
            if (!section.description && !section.title) {
                return false;
            }

            // Check for required fields
            if (!section.description || !section.title) {
                return true;
            }

            // Check length limits
            if (section.description.length > 1000 || section.title.length > 100) {
                return true;
            }

            return false;
        });
    };

    const hasTemplateChanged = () => {
        // Filter out empty sections before comparing
        const nonEmptySections = sections.filter((section) => section.title || section.description);
        const nonEmptyOriginalSections = originalSections.filter((section) => section.title || section.description);

        if (nonEmptySections.length !== nonEmptyOriginalSections.length) {
            return true;
        }

        return nonEmptySections.some((section, index) => {
            const originalSection = nonEmptyOriginalSections[index];
            return section.title !== originalSection.title || section.description !== originalSection.description;
        });
    };

    const handleSave = async () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'save_custom_template',
        });
        try {
            setIsSubmitting(true);
            setError(null);

            // Check for validation errors
            if (hasValidationErrors()) {
                setError('Please fix validation errors before saving');
                setIsSubmitting(false);
                return;
            }

            // Check for duplicate keys
            const keys = sections.map((s) => s.key);
            const hasDuplicateKeys = new Set(keys).size !== keys.length;

            if (hasDuplicateKeys) {
                setError('Each section must have a unique key');
                setIsSubmitting(false);
                return;
            }

            const templateData: CustomSummaryTemplateData = {
                name: templateName,
                sections,
            };

            const template = await postCreateCustomSummaryTemplate(templateData, editingTemplateId ?? undefined);
            if (!template) {
                setError('Failed to save template. Please try again.');
                setIsSubmitting(false);
                return;
            }
            setGlobalTemplates((templates) => {
                if (!templates?.length) {
                    return [template];
                }
                const index = templates.findIndex((t) => t._id === template?._id);
                if (index !== -1) {
                    return [...templates.slice(0, index), template, ...templates.slice(index + 1)];
                } else {
                    return [...templates, template];
                }
            });

            setOpenModal(null);
        } catch (err) {
            setError('Failed to save template. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const isOpen = modal === GlobalModal.CreateCustomSummaryTemplate;

    if (!isOpen) {
        return null;
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            style={{
                zIndex: 9999, // Ensure modal is above everything else
            }}
        >
            <SpinachModalContent
                onClose={handleClose}
                style={{
                    maxWidth: '900px',
                    maxHeight: '90vh',
                    overflow: 'auto',
                    position: 'relative', // Ensure stacking context
                }}
            >
                <Column style={{ alignItems: 'center', width: '100%', position: 'relative' }}>
                    <HeaderThree style={{ margin: '0 0 16px 0' }}>
                        {editingTemplateId ? 'Edit Template' : 'Create Template'}
                    </HeaderThree>

                    <InfoContainer>
                        <InfoOutlinedIcon
                            style={{ color: lightTheme.primary.green, marginRight: '8px', flexShrink: 0 }}
                        />
                        <BodyRegularOnboard>
                            Customize your summary by adding section titles, each creating a new section. Describe what
                            you want each section to contain, and we'll capture relevant meeting notes accordingly. To
                            remove a section, just delete all its contents.
                        </BodyRegularOnboard>
                    </InfoContainer>

                    {sections.map((section, index) => {
                        const isContentEmpty = !section.description && !section.title;
                        const hasContentError =
                            section.description.length > 1000 ||
                            section.title.length > 100 ||
                            (!section.description && !!section.title) ||
                            (!section.title && !!section.description);

                        return (
                            <BootstrapTooltip
                                key={index}
                                title={
                                    isContentEmpty
                                        ? 'Empty sections will be removed when saving'
                                        : hasContentError
                                        ? 'Both title and description are required. Title max 100 chars, description max 1000 chars.'
                                        : ''
                                }
                                placement="top"
                            >
                                <SectionContainer isContentEmpty={isContentEmpty} hasContentError={hasContentError}>
                                    <Row
                                        style={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <BodyRegular style={{ fontWeight: 500 }}>Section title</BodyRegular>
                                    </Row>

                                    <StyledInput
                                        {...withContentMasking()}
                                        value={section.title}
                                        onChange={(e) => handleSectionChange(index, 'title', e.target.value)}
                                        maxLength={100}
                                    />
                                    {section.title.length > 80 ? (
                                        <ValidationMessage type="info">
                                            {`${section.title.length}/100 characters`}
                                        </ValidationMessage>
                                    ) : null}

                                    <BodyRegular style={{ fontWeight: 500, marginTop: '16px' }}>
                                        Description
                                    </BodyRegular>
                                    <StyledTextarea
                                        {...withContentMasking()}
                                        value={section.description}
                                        onChange={(e) => {
                                            handleSectionChange(index, 'description', e.target.value);
                                            handleTextAreaHeight(e);
                                        }}
                                        onInput={handleTextAreaHeight}
                                        maxLength={1000}
                                    />
                                    {section.description.length > 800 ? (
                                        <ValidationMessage type="info">
                                            {`${section.description.length}/1000 characters`}
                                        </ValidationMessage>
                                    ) : null}
                                </SectionContainer>
                            </BootstrapTooltip>
                        );
                    })}

                    {error ? <BodyRegular style={{ color: 'red', marginBottom: '16px' }}>{error}</BodyRegular> : null}

                    <PrimaryButton
                        onClick={handleSave}
                        title="Save template"
                        isLoading={isSubmitting}
                        loadingText="Saving..."
                        disabled={isSubmitting || !hasTemplateChanged() || hasValidationErrors()}
                        style={{ width: 'auto', alignSelf: 'center' }}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
};
