import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { CustomSummaryTemplate } from '@spinach-shared/types';

import { getCustomSummaryTemplates } from '../apis/getCustomSummaryTemplates';
import { atomCustomTemplates } from '../atoms/atomCustomTemplates';
import { SetValue } from '../types';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useFetchGlobalCustomTemplates() {
    const [templates, setTemplates] = useRecoilState(atomCustomTemplates);
    const [user] = useGlobalAuthedUser();

    const fetchTemplates = useCallback(async () => {
        if (!user || user.isAnonymous) {
            return;
        }

        try {
            const fetchedTemplates = await getCustomSummaryTemplates();
            setTemplates(fetchedTemplates);
        } catch (error) {}
    }, [setTemplates, user]);

    useEffect(() => {
        if (!user.isEnabledForCustomSummaryTemplates) {
            setTemplates([]);
            return;
        }
        fetchTemplates();
    }, [fetchTemplates, user.isEnabledForCustomSummaryTemplates, setTemplates]);

    return [templates, setTemplates];
}
export function useGlobalCustomTemplates(): [CustomSummaryTemplate[] | null, SetValue<CustomSummaryTemplate[] | null>] {
    return useRecoilState(atomCustomTemplates);
}
