import { CustomSummaryTemplate, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

/**
 * Get all custom summary templates for the current user
 */
export async function getCustomSummaryTemplates(): Promise<CustomSummaryTemplate[]> {
    const response = await getSpinachAPI<{ templates: CustomSummaryTemplate[] }>(
        SpinachAPIPath.UserCustomSummaryTemplates
    );
    return response?.templates ?? [];
}
