import { Box } from '@material-ui/core';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { lightTheme } from '../../../../styles';

// Reuse the shimmer animation from existing components
const shimmer = keyframes`
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
`;

const ShimmerBox = styled.div<{ height?: string; margin?: string; width?: string }>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '24px'};
    margin: ${(props) => props.margin || '8px 0'};
    background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    background-size: 2000px 100%;
    animation: ${shimmer} 2s linear infinite;
    border-radius: 4px;
`;

const ShimmerContainer = styled.div`
    width: 100%;
    padding: 16px;
`;

// Video section shimmer
export const VideoSectionShimmer = () => (
    <ShimmerContainer>
        <ShimmerBox height="32px" margin="0 0 12px 0" width="60%" />
        <ShimmerBox height="16px" margin="0 0 24px 0" width="40%" />
        <ShimmerBox height="320px" margin="0 0 16px 0" />
        <ShimmerBox height="20px" margin="0 0 8px 0" width="70%" />
    </ShimmerContainer>
);

// Ask Spinach section shimmer
export const AskSpinachShimmer = () => (
    <ShimmerContainer>
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="24px" margin="0 0 8px 0" width="80%" />
        <ShimmerBox height="24px" margin="0 0 16px 0" width="60%" />
        <ShimmerBox height="48px" margin="0 0 16px 0" width="30%" />
    </ShimmerContainer>
);

// Summary section shimmer
export const SummaryShimmer = () => (
    <ShimmerContainer>
        <ShimmerBox height="32px" margin="0 0 16px 0" width="50%" />
        <ShimmerBox height="16px" margin="0 0 8px 0" width="100%" />
        <ShimmerBox height="16px" margin="0 0 8px 0" width="100%" />
        <ShimmerBox height="16px" margin="0 0 16px 0" width="80%" />
        
        <ShimmerBox height="32px" margin="0 0 16px 0" width="40%" />
        <ShimmerBox height="16px" margin="0 0 8px 0" width="100%" />
        <ShimmerBox height="16px" margin="0 0 8px 0" width="90%" />
        <ShimmerBox height="16px" margin="0 0 16px 0" width="95%" />
    </ShimmerContainer>
);

// Transcript section shimmer
export const TranscriptShimmer = () => (
    <ShimmerContainer>
        {[...Array(5)].map((_, index) => (
            <Box key={index} display="flex" flexDirection="column" mb={2}>
                <Box display="flex" mb={1}>
                    <ShimmerBox height="16px" width="60px" margin="0 8px 0 0" />
                    <ShimmerBox height="16px" width="150px" />
                </Box>
                <ShimmerBox height="16px" margin="0 0 0 50px" />
            </Box>
        ))}
    </ShimmerContainer>
);
