import { Box } from '@material-ui/core';
import { CheckCircle, Lock } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_TYPE_FEATURE_INTENT_MAP, ScribeMeetingTypeLabelMap } from '@spinach-shared/constants';
import {
    ClientEventType,
    CustomizableSummaryFeatureIntents,
    FeatureIntent,
    ScribeMeetingType,
    SummaryFeatureIntents,
} from '@spinach-shared/types';
import { StoredSpinachSeries, getUniques, haveSummarySectionsBeenEdited } from '@spinach-shared/utils';

import { patchSeries } from '../../../../apis';
import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalCustomTemplates,
    useGlobalModal,
    useIncludeUserResearchInterviewInMeetingTypeSelection,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ListItemValue } from '../../../../types';
import { Column, Row } from '../../../common';
import { MeetingTypeSelector } from '../summaries/MeetingTypeSelector';
import { SettingsContent, SettingsSubTitle } from './CombinedMeetingDetails';
import { SummaryExampleTemplate } from './SummaryExampleTemplates';

const HoveredBodyRegularOnboard = styled(BodyRegularOnboard)`
    &:hover {
        font-weight: 600;
    }
`;

const CustomizableCategories: Record<CustomizableSummaryFeatureIntents, string> = {
    [FeatureIntent.MeetingAgendaItems]: 'Key Topics',
    [FeatureIntent.MeetingActionItems]: 'Action Items',
    [FeatureIntent.MeetingKeyDecisions]: 'Key Decisions',
    [FeatureIntent.MeetingBlockers]: 'Blockers',
    [FeatureIntent.MeetingWhatWentWell]: 'What Went Well',
    [FeatureIntent.MeetingWhatDidNotGoWell]: 'What Did Not Go Well',
    [FeatureIntent.MeetingWhatCanBeImproved]: 'What Can Be Improved',
    [FeatureIntent.MeetingCelebrations]: 'Celebrations',
    [FeatureIntent.PerPersonUpdates]: 'Plans and Progress',
    [FeatureIntent.MeetingWorkstreams]: 'Workstreams',
};

export function CustomSummaryDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [, setOpenModal] = useGlobalModal();
    const [templates] = useGlobalCustomTemplates();
    const hasCustomTemplates = templates && templates?.length > 0;
    const { setToastText } = useGlobalAiDashboard();
    const includeResearchMeeting = useIncludeUserResearchInterviewInMeetingTypeSelection();
    const includeGeneralSalesMeeting = user.isEnabledForGeneralSalesMeetingType;
    const includeCustomerSuccessCheckIn = user.isEnabledForCustomerSuccessCheckInMeetingType;
    const includeCompanyAllHandsMeeting = user.isEnabledForCompanyAllHandsMeetingType;

    let meetingTypeValues: ListItemValue<ScribeMeetingType, string>[] = Object.values(ScribeMeetingType)
        .map((meetingType) => ({
            code: meetingType,
            label: ScribeMeetingTypeLabelMap[meetingType],
            isTooltipInteractive: false,
            tooltip: <SummaryExampleTemplate meetingType={meetingType} />,
            arrowStyle: {
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
            tooltipStyle: {
                maxWidth: '500px',
                alignItems: 'start',
                justifyItems: 'start',
                width: '100%',
                borderRadius: '15px',
                padding: '15px',
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
        }))
        .filter((option) => option.code !== ScribeMeetingType.Custom);

    // Add custom template option
    if (user.isEnabledForCustomSummaryTemplates) {
        const customTemplateOption: ListItemValue<ScribeMeetingType> = {
            code: ScribeMeetingType.Custom,
            label: !hasCustomTemplates ? 'Create custom template' : 'Custom template',
            isTooltipInteractive: true,
            tooltip: (
                <Row>
                    <BodyRegularOnboard>
                        Your custom template, you can edit your custom summary template by clicking{' '}
                        <span
                            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                            onClick={() =>
                                setOpenModal(GlobalModal.CreateCustomSummaryTemplate, {
                                    metadata: {
                                        metadataType: GlobalModalMetadataType.CreateCustomSummaryTemplate,
                                        templateId: templates?.[0]?._id,
                                    },
                                })
                            }
                        >
                            here
                        </span>
                    </BodyRegularOnboard>
                </Row>
            ),
            arrowStyle: {
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
            tooltipStyle: {
                maxWidth: '500px',
                alignItems: 'start',
                justifyItems: 'start',
                width: '100%',
                borderRadius: '15px',
                padding: '15px',
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
        };

        // If the user has no templates, modify the label and add onClick handler
        if (!hasCustomTemplates) {
            // Create a custom React element for the label with green styling
            customTemplateOption.customLabel = (
                <Row
                    style={{
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <span
                        style={{
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            textAlign: 'left',
                            alignItems: 'center',
                            textDecoration: 'none',
                            minHeight: 'auto',
                            width: '100%',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            fontSize: '1rem',
                            boxSizing: 'border-box',
                            fontWeight: 400,
                            lineHeight: '1.5',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Create custom template
                    </span>
                </Row>
            );

            customTemplateOption.onClick = () => {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Create Custom Template',
                    From: 'meeting_settings',
                });
                setOpenModal(GlobalModal.CreateCustomSummaryTemplate);
                return true; // Prevent default selection behavior
            };
        } else {
            // If the user has templates, just add the edit icon with normal styling
            customTemplateOption.customLabel = (
                <Row
                    style={{
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <span
                        style={{
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            textAlign: 'left',
                            alignItems: 'center',
                            textDecoration: 'none',
                            minHeight: 'auto',
                            width: '100%',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            fontSize: '1rem',
                            boxSizing: 'border-box',
                            fontWeight: 400,
                            lineHeight: '1.5',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Custom template
                    </span>
                </Row>
            );
        }

        meetingTypeValues.unshift(customTemplateOption);
    }

    // if feature flag is disabled remove it from the list of options
    if (!includeResearchMeeting) {
        const item = meetingTypeValues.find((i) => i.code === ScribeMeetingType.UserResearchInterview);
        if (item) {
            item.disabled = true;
            item.hidePreContentAvatar = true;
            item.preContent = (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0D674A',
                        width: '24px',
                        height: '24px',
                        marginLeft: '15px',
                        marginRight: '2px',
                        borderRadius: '2px',
                    }}
                >
                    <Lock style={{ color: 'white', width: '18px', height: '18px' }} />
                </Box>
            );
        }
    }

    if (!includeCustomerSuccessCheckIn) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.CustomerSuccessCheckIn);
    }

    if (!includeGeneralSalesMeeting) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.GeneralSalesMeeting);
    }

    if (!includeCompanyAllHandsMeeting) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.CompanyAllHands);
    }

    const [selectedMeetingType, setSelectedMeetingType] = useState<ScribeMeetingType>(
        storedSeries.meetingType ?? ScribeMeetingType.Generic
    );

    const [enabledSections, setEnabledSections] = useState<SummaryFeatureIntents[]>(
        storedSeries.enabledSummarySections ?? MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingType]
    );

    const [hasShownToast, setHasShownToast] = useState(
        haveSummarySectionsBeenEdited(storedSeries.meetingType, enabledSections)
    );

    useEffect(() => {
        setEnabledSections(
            selectedMeetingType === storedSeries.meetingType
                ? enabledSections ?? MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingType]
                : MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingType]
        );
    }, [selectedMeetingType, enabledSections, setEnabledSections, storedSeries.meetingType]);

    const customizableSections = Object.keys(CustomizableCategories).filter((category) =>
        MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingType].includes(category as SummaryFeatureIntents)
    );

    const {
        storedSeriesListState: { storedSeriesList },
        setStoredSeriesList,
    } = useGlobalStoredSeriesList();

    const patchStoredSeriesList = (patchedStoredSeries: StoredSpinachSeries) => {
        const newStoredSeriesList = storedSeriesList.map((series) =>
            series.id === patchedStoredSeries.id ? patchedStoredSeries : series
        );
        setStoredSeriesList(newStoredSeriesList);
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleMeetingTypeChange = async (meetingType: ScribeMeetingType, customTemplateId?: string) => {
        try {
            setIsLoading(true);
            setSelectedMeetingType(meetingType);
            const updatedSeries = await patchSeries(storedSeries.id, {
                metadata: {
                    scribeMetadata: {
                        meetingType,
                        customSummaryTemplateId: customTemplateId || null,
                        hasMeetingTypeBeenModifiedByUser: true,
                        enabledSummarySections: MEETING_TYPE_FEATURE_INTENT_MAP[meetingType],
                    },
                },
            });
            if (updatedSeries) {
                patchStoredSeriesList(new StoredSpinachSeries(updatedSeries));
            }
        } catch (error) {
            setToastText('Error updating in meeting setting');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <SettingsSubTitle>{'Summary Template'}</SettingsSubTitle>
            <SettingsContent>
                <BodyRegularOnboard>
                    <Row style={{ flexWrap: 'wrap' }}>
                        <Box my="auto" mr="10px" fontSize="16px">
                            {'Use this template for this meeting: '}
                        </Box>
                        <MeetingTypeSelector
                            selectedMeetingType={selectedMeetingType}
                            onMeetingTypeChange={handleMeetingTypeChange}
                            isDisabled={isLoading}
                            includeResearchMeeting={includeResearchMeeting}
                            includeGeneralSalesMeeting={includeGeneralSalesMeeting}
                            includeCustomerSuccessCheckIn={includeCustomerSuccessCheckIn}
                            includeCompanyAllHandsMeeting={includeCompanyAllHandsMeeting}
                            isEnabledForCustomSummaryTemplates={user.isEnabledForCustomSummaryTemplates}
                            from="meeting_settings"
                            seriesId={storedSeries.id}
                            lastKnownBotId={storedSeries.scribeMetadata?.lastKnownBotId}
                            iCalUid={storedSeries.scribeMetadata?.iCalUid}
                            buttonStyle={{
                                transform: 'scale(0.8)',
                                transformOrigin: 'center left',
                                padding: '4px 24px',
                            }}
                            customTemplateInfo={storedSeries.customTemplateInfo}
                        />
                    </Row>
                </BodyRegularOnboard>

                <Column>
                    {customizableSections.map((featureIntent) => (
                        <Row key={featureIntent} vCenter>
                            <HoveredBodyRegularOnboard
                                onClick={async () => {
                                    try {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Summary Section Checkbox',
                                            LastKnownBotId: storedSeries.scribeMetadata?.lastKnownBotId,
                                            SeriesId: storedSeries.id,
                                            ICalUID: storedSeries.scribeMetadata?.iCalUid,
                                            ToggledSection: featureIntent,
                                            CurrentlyEnabledSections: enabledSections,
                                            IsTogglingOn: !enabledSections.includes(
                                                featureIntent as CustomizableSummaryFeatureIntents
                                            ),
                                            IsTogglingOff: enabledSections.includes(
                                                featureIntent as CustomizableSummaryFeatureIntents
                                            ),
                                            CurrentMeetingType: storedSeries.meetingType,
                                        });
                                        setIsLoading(true);

                                        const updatedEnabledSections = enabledSections.includes(
                                            featureIntent as SummaryFeatureIntents
                                        )
                                            ? enabledSections.filter((section) => section !== featureIntent)
                                            : getUniques([...enabledSections, featureIntent as SummaryFeatureIntents]);

                                        if (
                                            !user.isEnabledForSupportEditAfterCustomizingSections &&
                                            haveSummarySectionsBeenEdited(
                                                storedSeries.meetingType,
                                                updatedEnabledSections
                                            ) &&
                                            !hasShownToast &&
                                            storedSeries.metadata.scribeMetadata?.isHostEditing
                                        ) {
                                            setToastText(
                                                'Edit summary has been disabled. This cannot be used with a custom summary template.'
                                            );
                                            setHasShownToast(true);
                                        }
                                        setEnabledSections(updatedEnabledSections);
                                        const patchedSeries = await patchSeries(storedSeries.id, {
                                            metadata: {
                                                scribeMetadata: {
                                                    enabledSummarySections: updatedEnabledSections,
                                                },
                                            },
                                        });

                                        if (patchedSeries) {
                                            patchStoredSeriesList(new StoredSpinachSeries(patchedSeries));
                                        }
                                    } catch (error) {
                                        setToastText('Error updating summary template');
                                    } finally {
                                        setIsLoading(false);
                                    }
                                }}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                                <CheckCircle
                                    style={{
                                        color: enabledSections.includes(featureIntent as SummaryFeatureIntents)
                                            ? lightTheme.primary.greenLight
                                            : lightTheme.neutrals.grayDark,
                                    }}
                                    htmlColor={lightTheme.neutrals.white}
                                />

                                {CustomizableCategories[featureIntent as CustomizableSummaryFeatureIntents]}
                            </HoveredBodyRegularOnboard>
                        </Row>
                    ))}
                </Column>
            </SettingsContent>
        </>
    );
}
