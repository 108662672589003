import { Box, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import { getUser } from '../../../apis';
import { updateCRMIntegrationSettings } from '../../../apis/updateCRMIntegrationSettings';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../hooks';
import { SpinachSwitch } from '../../common/SpinachSwitch';

interface CRMCreateTasksToggleProps {
    integration: SpinachIntegration;
    isEnabled: boolean;
    isVisible: boolean;
}

export const CRMCreateTasksToggle: React.FC<CRMCreateTasksToggleProps> = ({ integration, isEnabled, isVisible }) => {
    const [, setUser] = useGlobalAuthedUser();
    const [isChecked, setIsChecked] = useState(isEnabled);
    const track = useExperienceTracking();

    if (!isVisible) {
        return null;
    }

    const handleToggleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setIsChecked(newValue);

        try {
            await updateCRMIntegrationSettings(integration, newValue);
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Toggle CRM Create Tasks',
                CRMProvider: integration,
                isCreateTasksEnabled: newValue,
            });

            // Refresh user to get updated settings
            const userResponse = await getUser();
            if (userResponse.user) {
                setUser(userResponse.user);
            }
        } catch (error) {
            // Revert UI state on error
            setIsChecked(isEnabled);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Box
                    style={{
                        fontWeight: 500,
                        fontSize: '17px',
                        textTransform: 'uppercase',
                        letterSpacing: '1.5px',
                    }}
                >
                    Task Creation
                </Box>
                <FormControlLabel
                    control={<SpinachSwitch checked={isChecked} onChange={handleToggleChange} />}
                    label="Create tasks"
                    style={{ marginTop: '10px', marginLeft: '-2px' }}
                />

                <Box style={{ fontSize: '15px', marginTop: '5px' }}>
                    When enabled, tasks will be automatically created when notes are created
                </Box>
            </Box>
        </>
    );
};
