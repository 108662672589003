import { SpinachAPIPath, SpinachIntegration } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export const updateCRMIntegrationSettings = async (
    oauthProvider: SpinachIntegration,
    isCreateTasksEnabled: boolean
): Promise<void> => {
    await postSpinachAPI(SpinachAPIPath.UpdateCRMIntegrationSettings.replace(':oauthProvider', oauthProvider), {
        isCreateTasksEnabled,
    });
};
