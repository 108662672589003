import { TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientAiHistory, SpinachAPIPath } from '@spinach-shared/types';

import { getAiHistories } from '../../../../apis/getAiHistories';
import { postSpinachAPI } from '../../../../apis/postSpinachAPI';
import { ClientLogger } from '../../../../utils';
import { Column, Row } from '../../../common';
import { PrimaryButton } from '../../../stand-up/PrimaryButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';

const PlannerContainer = styled(Column)`
    padding: 20px;
    height: 100%;
    align-items: center;
    overflow-y: auto;
`;

const FormContainer = styled(Column)`
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const FormTitle = styled.h2`
    font-size: 18px;
    margin-bottom: 16px;
    color: #333;
`;

const FormDescription = styled.p`
    font-size: 14px;
    margin-bottom: 16px;
    color: #666;
`;

const TextArea = styled(TextareaAutosize)`
    min-height: 100px;
    font-size: 16px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    resize: vertical;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
    font-family: inherit;
`;

const InputLabel = styled.label`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #333;
`;

const PromptLink = styled.a`
    font-size: 12px;
    color: #0077cc;
    margin-left: 8px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const LabelRow = styled(Row)`
    align-items: center;
`;

const ResponseContainer = styled.div`
    margin-top: 16px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    font-family: monospace;
    width: 100%;
    min-height: 100px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
`;

const ButtonContainer = styled(Row)`
    justify-content: flex-end;
    margin-top: 16px;
    gap: 12px;
`;

const MeetingCheckboxContainer = styled.div`
    max-height: 30vh;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 8px;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    padding: 4px 0;
    cursor: pointer;
    font-size: 14px;
    &:hover {
        background-color: #f5f5f5;
    }
`;

const MeetingTitle = styled.span`
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MeetingDate = styled.span`
    margin-left: auto;
    font-size: 12px;
    color: #666;
`;

const SelectedCount = styled.div`
    font-size: 12px;
    color: #666;
    margin-bottom: 8px;
`;

const SelectedIds = styled.div`
    font-size: 12px;
    color: #666;
    margin-bottom: 16px;
    word-break: break-all;
`;

export function PlannerSection(): JSX.Element {
    // PRD Form State
    const [prdText, setPrdText] = useState('');
    const [selectedMeetings, setSelectedMeetings] = useState<string[]>([]);
    const [recentMeetings, setRecentMeetings] = useState<ClientAiHistory[]>([]);
    const [loadingMeetings, setLoadingMeetings] = useState(false);
    const [prdPromptOverride, setPrdPromptOverride] = useState('');
    const [prdResponse, setPrdResponse] = useState('');
    const [loadingPrd, setLoadingPrd] = useState(false);

    // Plan Form State
    const [planPrd, setPlanPrd] = useState('');
    const [planPromptOverride, setPlanPromptOverride] = useState('');
    const [planResponse, setPlanResponse] = useState('');
    const [loadingPlan, setLoadingPlan] = useState(false);

    // Subtasks Form State
    const [subtasksPrd, setSubtasksPrd] = useState('');
    const [subtasksPlan, setSubtasksPlan] = useState('');
    const [subtasksPromptOverride, setSubtasksPromptOverride] = useState('');
    const [subtasksResponse, setSubtasksResponse] = useState('');
    const [loadingSubtasks, setLoadingSubtasks] = useState(false);

    // Fetch recent meetings
    useEffect(() => {
        const fetchMeetings = async () => {
            try {
                setLoadingMeetings(true);
                const meetings = await getAiHistories({ limit: 100 });
                if (meetings?.histories) {
                    setRecentMeetings(meetings.histories);
                }
            } catch (error) {
                ClientLogger.error('Error fetching meetings:', { error });
            } finally {
                setLoadingMeetings(false);
            }
        };

        fetchMeetings();
    }, []);

    const handleMeetingToggle = (botId: string) => {
        setSelectedMeetings((prev) => {
            if (prev.includes(botId)) {
                return prev.filter((id) => id !== botId);
            } else {
                return [...prev, botId];
            }
        });
    };

    const handleGeneratePRD = async () => {
        try {
            setLoadingPrd(true);
            const meetingIdsArray = selectedMeetings;

            const response = await postSpinachAPI<{ prd: string }>(
                SpinachAPIPath.GeneratePRD,
                {
                    text: prdText.trim(),
                    meetingIds: meetingIdsArray,
                    promptOverride: prdPromptOverride.trim() ? prdPromptOverride : undefined,
                },
                {
                    timeout: 600000,
                }
            );

            if (response?.prd) {
                setPrdResponse(response.prd);
                setPlanPrd(response.prd);
                setSubtasksPrd(response.prd);
            }
        } catch (error) {
            ClientLogger.error('Error generating PRD:', { error });
            setPrdResponse('Error generating PRD. Please try again.');
        } finally {
            setLoadingPrd(false);
        }
    };

    const handleGeneratePlan = async () => {
        try {
            setLoadingPlan(true);

            const response = await postSpinachAPI<{ plan: string }>(
                SpinachAPIPath.GenerateCodeAnalysisPlan,
                {
                    prd: planPrd.trim() ? planPrd : undefined,
                    promptOverride: planPromptOverride.trim() ? planPromptOverride : undefined,
                },
                {
                    timeout: 600000,
                }
            );

            if (response?.plan) {
                setPlanResponse(response.plan);
                setSubtasksPlan(response.plan);
            }
        } catch (error) {
            ClientLogger.error('Error generating plan:', { error });
            setPlanResponse('Error generating plan. Please try again.');
        } finally {
            setLoadingPlan(false);
        }
    };

    const handleGenerateSubtasks = async () => {
        try {
            setLoadingSubtasks(true);

            const response = await postSpinachAPI<{ subtasks: string }>(
                SpinachAPIPath.GenerateCodeAnalysisSubtasks,
                {
                    prd: subtasksPrd.trim() ? subtasksPrd : undefined,
                    plan: subtasksPlan.trim() ? subtasksPlan : undefined,
                    promptOverride: subtasksPromptOverride.trim() ? subtasksPromptOverride : undefined,
                },
                {
                    timeout: 600000,
                }
            );

            if (response?.subtasks) {
                setSubtasksResponse(response.subtasks);
            }
        } catch (error) {
            ClientLogger.error('Error generating subtasks:', { error });
            setSubtasksResponse('Error generating subtasks. Please try again.');
        } finally {
            setLoadingSubtasks(false);
        }
    };

    return (
        <PlannerContainer>
            <h1>Project Planner</h1>

            {/* PRD Generation Form */}
            <FormContainer>
                <FormTitle>Generate PRD</FormTitle>
                <FormDescription>Generate a Product Requirements Document from text and meeting IDs.</FormDescription>

                <InputLabel>Text Description</InputLabel>
                <TextArea
                    value={prdText}
                    onChange={(e) => setPrdText(e.target.value)}
                    placeholder="Enter a description of the product requirements"
                />

                <InputLabel>Select Meetings</InputLabel>
                <MeetingCheckboxContainer>
                    {loadingMeetings ? (
                        <div>Loading meetings...</div>
                    ) : recentMeetings.length === 0 ? (
                        <div>No recent meetings found</div>
                    ) : (
                        recentMeetings.map((meeting) => (
                            <CheckboxLabel key={meeting.botId}>
                                <input
                                    type="checkbox"
                                    checked={selectedMeetings.includes(meeting.botId)}
                                    onChange={() => handleMeetingToggle(meeting.botId)}
                                />
                                <MeetingTitle>{meeting.meetingTitle}</MeetingTitle>
                                <MeetingDate>{new Date(meeting.createdAt).toLocaleDateString()}</MeetingDate>
                            </CheckboxLabel>
                        ))
                    )}
                </MeetingCheckboxContainer>
                <SelectedCount>Selected meetings: {selectedMeetings.length}</SelectedCount>
                {selectedMeetings.length > 0 && <SelectedIds>Meeting IDs: {selectedMeetings.join(', ')}</SelectedIds>}

                <LabelRow>
                    <InputLabel>Prompt Override (Optional)</InputLabel>
                    <PromptLink
                        href="https://us-east-2.console.aws.amazon.com/codesuite/codecommit/repositories/prompts/browse/refs/heads/test/--/prd/default/prompt.template?region=us-east-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        (View original prompt template)
                    </PromptLink>
                </LabelRow>
                <TextArea
                    value={prdPromptOverride}
                    onChange={(e) => setPrdPromptOverride(e.target.value)}
                    placeholder="Enter a custom prompt if needed"
                    minRows={2}
                />

                <ButtonContainer>
                    <SecondaryButton
                        title="Reset"
                        onClick={() => {
                            setPrdText('');
                            setSelectedMeetings([]);
                            setPrdPromptOverride('');
                            setPrdResponse('');
                        }}
                    />
                    <PrimaryButton
                        title={loadingPrd ? 'Generating...' : 'Generate PRD'}
                        onClick={handleGeneratePRD}
                        disabled={!prdText.trim() || selectedMeetings.length === 0 || loadingPrd}
                        isLoading={loadingPrd}
                    />
                </ButtonContainer>

                {prdResponse && (
                    <>
                        <InputLabel>PRD Result</InputLabel>
                        <ResponseContainer>{prdResponse}</ResponseContainer>
                    </>
                )}
            </FormContainer>

            {/* Code Analysis Plan Form */}
            <FormContainer>
                <FormTitle>Generate Code Analysis Plan</FormTitle>
                <FormDescription>Create a plan for implementing the requirements in code.</FormDescription>

                <InputLabel>PRD (Product Requirements Document)</InputLabel>
                <TextArea
                    value={planPrd}
                    onChange={(e) => setPlanPrd(e.target.value)}
                    placeholder="Enter your PRD content"
                />

                <LabelRow>
                    <InputLabel>Prompt Override (Optional)</InputLabel>
                    <PromptLink
                        href="https://us-east-2.console.aws.amazon.com/codesuite/codecommit/repositories/prompts/browse/refs/heads/test/--/code-analysis/plan/prompt.template?region=us-east-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        (View original prompt template)
                    </PromptLink>
                </LabelRow>
                <TextArea
                    value={planPromptOverride}
                    onChange={(e) => setPlanPromptOverride(e.target.value)}
                    placeholder="Enter a custom prompt if needed"
                    minRows={2}
                />

                <ButtonContainer>
                    <SecondaryButton
                        title="Reset"
                        onClick={() => {
                            setPlanPrd('');
                            setPlanPromptOverride('');
                            setPlanResponse('');
                        }}
                    />
                    <PrimaryButton
                        title={loadingPlan ? 'Generating...' : 'Generate Plan'}
                        onClick={handleGeneratePlan}
                        disabled={(!planPrd.trim() && !planPromptOverride.trim()) || loadingPlan}
                        isLoading={loadingPlan}
                    />
                </ButtonContainer>

                {planResponse && (
                    <>
                        <InputLabel>Plan Result</InputLabel>
                        <ResponseContainer>{planResponse}</ResponseContainer>
                    </>
                )}
            </FormContainer>

            {/* Code Analysis Subtasks Form */}
            <FormContainer>
                <FormTitle>Generate Code Analysis Subtasks</FormTitle>
                <FormDescription>Break down the plan into specific implementation subtasks.</FormDescription>

                <InputLabel>PRD (Optional if Plan and Prompt Override provided)</InputLabel>
                <TextArea
                    value={subtasksPrd}
                    onChange={(e) => setSubtasksPrd(e.target.value)}
                    placeholder="Enter your PRD content"
                />

                <InputLabel>Plan (Optional if Prompt Override provided)</InputLabel>
                <TextArea
                    value={subtasksPlan}
                    onChange={(e) => setSubtasksPlan(e.target.value)}
                    placeholder="Enter your plan content"
                />

                <LabelRow>
                    <InputLabel>Prompt Override (Optional)</InputLabel>
                    <PromptLink
                        href="https://us-east-2.console.aws.amazon.com/codesuite/codecommit/repositories/prompts/browse/refs/heads/test/--/code-analysis/subtasks/prompt.template?region=us-east-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        (View original prompt template)
                    </PromptLink>
                </LabelRow>
                <TextArea
                    value={subtasksPromptOverride}
                    onChange={(e) => setSubtasksPromptOverride(e.target.value)}
                    placeholder="Enter a custom prompt if needed"
                    minRows={2}
                />

                <ButtonContainer>
                    <SecondaryButton
                        title="Reset"
                        onClick={() => {
                            setSubtasksPrd('');
                            setSubtasksPlan('');
                            setSubtasksPromptOverride('');
                            setSubtasksResponse('');
                        }}
                    />
                    <PrimaryButton
                        title={loadingSubtasks ? 'Generating...' : 'Generate Subtasks'}
                        onClick={handleGenerateSubtasks}
                        disabled={
                            ((!subtasksPrd.trim() || !subtasksPlan.trim()) && !subtasksPromptOverride.trim()) ||
                            loadingSubtasks
                        }
                        isLoading={loadingSubtasks}
                    />
                </ButtonContainer>

                {subtasksResponse && (
                    <>
                        <InputLabel>Subtasks Result</InputLabel>
                        <ResponseContainer>{subtasksResponse}</ResponseContainer>
                    </>
                )}
            </FormContainer>
        </PlannerContainer>
    );
}
