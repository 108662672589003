import { Box } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { CSSProperties, useEffect } from 'react';

import { ScribeMeetingTypeLabelMap } from '@spinach-shared/constants';
import { ClientEventType, ScribeMeetingType } from '@spinach-shared/types';

import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import { useExperienceTracking, useGlobalCustomTemplates, useGlobalModal } from '../../../../hooks';
import { BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ListItemValue } from '../../../../types';
import { Row } from '../../../common';
import { DropDown } from '../../../common/DropDown';
import { SummaryExampleTemplate } from '../meetings/SummaryExampleTemplates';

interface MeetingTypeSelectorProps {
    selectedMeetingType: ScribeMeetingType;
    onMeetingTypeChange: (meetingType: ScribeMeetingType, customTemplateId?: string) => void;
    isDisabled?: boolean;
    showTooltip?: boolean;
    tooltipText?: string;
    includeResearchMeeting?: boolean;
    includeGeneralSalesMeeting?: boolean;
    includeCustomerSuccessCheckIn?: boolean;
    includeCompanyAllHandsMeeting?: boolean;
    isEnabledForCustomSummaryTemplates?: boolean;
    className?: string;
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
    dropdownStyle?: CSSProperties;
    from?: string;
    seriesId?: string;
    lastKnownBotId?: string;
    iCalUid?: string;
    customTemplateId?: string;
    customTemplateInfo?: {
        templateName: string;
        templateOwnerName: string;
        templateOwnerId: string;
    };
    analyticsContext?: string;
}

export function MeetingTypeSelector({
    selectedMeetingType,
    onMeetingTypeChange,
    isDisabled = false,
    includeResearchMeeting = false,
    includeGeneralSalesMeeting = false,
    includeCustomerSuccessCheckIn = false,
    includeCompanyAllHandsMeeting = false,
    isEnabledForCustomSummaryTemplates = false,
    className,
    style,
    buttonStyle,
    dropdownStyle,
    from,
    seriesId,
    lastKnownBotId,
    iCalUid,
    customTemplateInfo,
}: MeetingTypeSelectorProps): JSX.Element {
    const track = useExperienceTracking();
    const [, setOpenModal] = useGlobalModal();
    const [templates] = useGlobalCustomTemplates();
    const hasCustomTemplates = templates && templates?.length > 0;

    // If feature is disabled and selected type is custom, fallback to generic
    useEffect(() => {
        if (!isEnabledForCustomSummaryTemplates && selectedMeetingType === ScribeMeetingType.Custom) {
            onMeetingTypeChange(ScribeMeetingType.Generic);
        }
    }, [isEnabledForCustomSummaryTemplates, selectedMeetingType]);

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent dropdown from opening
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: hasCustomTemplates ? 'Edit Custom Template' : 'Create Custom Template',
            From: from,
        });
        setOpenModal(GlobalModal.CreateCustomSummaryTemplate, {
            metadata: {
                metadataType: GlobalModalMetadataType.CreateCustomSummaryTemplate,
                templateId: hasCustomTemplates ? templates[0]._id : undefined,
            },
        });
    };

    let meetingTypeValues: ListItemValue<ScribeMeetingType, string>[] = Object.values(ScribeMeetingType)
        .map((meetingType) => ({
            code: meetingType,
            label: ScribeMeetingTypeLabelMap[meetingType],
            isTooltipInteractive: false,
            tooltip: <SummaryExampleTemplate meetingType={meetingType} />,
            arrowStyle: {
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
            tooltipStyle: {
                maxWidth: '500px',
                alignItems: 'start',
                justifyItems: 'start',
                width: '100%',
                borderRadius: '15px',
                padding: '15px',
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
        }))
        .filter((option) => option.code !== ScribeMeetingType.Custom);

    // Add custom template option if enabled
    if (isEnabledForCustomSummaryTemplates) {
        const isCustomTemplateOwner =
            from === 'upload_section'
                ? true
                : customTemplateInfo?.templateOwnerId && customTemplateInfo.templateOwnerId === templates?.[0]?.userId;

        const customTemplateOption: ListItemValue<ScribeMeetingType> = {
            code: ScribeMeetingType.Custom,
            label: !hasCustomTemplates ? 'Create custom template' : 'Custom template',
            isTooltipInteractive: true,
            tooltip: (
                <Row>
                    {isCustomTemplateOwner || selectedMeetingType !== ScribeMeetingType.Custom ? (
                        <BodyRegularOnboard>
                            Your custom template, you can edit your custom summary template by clicking{' '}
                            <span
                                style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                                onClick={handleEditClick}
                            >
                                here
                            </span>{' '}
                        </BodyRegularOnboard>
                    ) : (
                        <BodyRegularOnboard>
                            {customTemplateInfo?.templateOwnerName ?? 'Someone else'}'s Custom Template, you cannot edit
                            this template
                        </BodyRegularOnboard>
                    )}
                </Row>
            ),
            arrowStyle: {
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
            tooltipStyle: {
                maxWidth: '500px',
                alignItems: 'start',
                justifyItems: 'start',
                width: '100%',
                borderRadius: '15px',
                padding: '15px',
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            },
        };

        let displayLabel = !hasCustomTemplates ? 'Create custom template' : 'Custom template';

        if (selectedMeetingType === ScribeMeetingType.Custom && customTemplateInfo) {
            const label = isCustomTemplateOwner
                ? 'Your custom template'
                : `${customTemplateInfo.templateOwnerName ?? 'Someone else'}'s custom template`;
            customTemplateOption.label = label;
            displayLabel = label;
        }

        customTemplateOption.customLabel = (
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <span
                    style={{
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        textAlign: 'left',
                        alignItems: 'center',
                        textDecoration: 'none',
                        minHeight: 'auto',
                        width: '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        fontSize: '1rem',
                        boxSizing: 'border-box',
                        fontWeight: 400,
                        lineHeight: '1.5',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {displayLabel}
                </span>
            </Box>
        );

        if (!hasCustomTemplates) {
            customTemplateOption.onClick = () => {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Create Custom Template',
                    From: from,
                });
                setOpenModal(GlobalModal.CreateCustomSummaryTemplate);
                return true; // Prevent default selection behavior
            };
        }

        meetingTypeValues.unshift(customTemplateOption);
    }

    // Filter out meeting types based on permissions
    if (!includeResearchMeeting) {
        const item = meetingTypeValues.find((i) => i.code === ScribeMeetingType.UserResearchInterview);
        if (item) {
            item.disabled = true;
            item.hidePreContentAvatar = true;
            item.preContent = (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0D674A',
                        width: '24px',
                        height: '24px',
                        marginLeft: '15px',
                        marginRight: '2px',
                        borderRadius: '2px',
                    }}
                >
                    <Lock style={{ color: 'white', width: '18px', height: '18px' }} />
                </Box>
            );
        }
    }

    if (!includeCustomerSuccessCheckIn) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.CustomerSuccessCheckIn);
    }

    if (!includeGeneralSalesMeeting) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.GeneralSalesMeeting);
    }

    if (!includeCompanyAllHandsMeeting) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.CompanyAllHands);
    }

    const handleSelection = async (selectedMeetingType: ScribeMeetingType, value: ListItemValue<ScribeMeetingType>) => {
        // Handle special case for custom template with no templates created
        if (value.onClick && selectedMeetingType === ScribeMeetingType.Custom) {
            const preventDefault = value.onClick();
            if (preventDefault) {
                return;
            }
        }

        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Meeting Type Dropdown',
            LastKnownBotId: lastKnownBotId,
            SeriesId: seriesId,
            ICalUID: iCalUid,
            SelectedMeetingType: selectedMeetingType,
            CurrentMeetingType: selectedMeetingType,
            Context: from,
        });

        if (isEnabledForCustomSummaryTemplates && value.code === ScribeMeetingType.Custom && !hasCustomTemplates) {
            setOpenModal(GlobalModal.CreateCustomSummaryTemplate);
            return;
        }

        onMeetingTypeChange(
            selectedMeetingType,
            value.code === ScribeMeetingType.Custom ? templates?.[0]?._id : undefined
        );
    };

    return (
        <div className={className} style={style}>
            <DropDown
                title="Select Meeting Template"
                values={meetingTypeValues}
                selected={selectedMeetingType}
                handleSelection={handleSelection}
                disabled={isDisabled}
                style={dropdownStyle}
                buttonProps={{
                    style: buttonStyle,
                }}
                menuProps={{
                    // Ensure menu is rendered at full scale and properly positioned
                    style: {
                        transform: 'scale(1)',
                        transformOrigin: 'top left',
                    },
                }}
            />
        </div>
    );
}
