import { useState } from 'react';

import { ScribeMeetingTypeLabelMap } from '@spinach-shared/constants';
import { ClientEventType, ScribeMeetingType, UserMetadata } from '@spinach-shared/types';

import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalCustomTemplates,
    useGlobalModal,
} from '../../../../hooks';
import { BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ListItemValue, SetValue } from '../../../../types';
import { Column, DropDown, Row } from '../../../common';

export type MeetingTemplateConfig = {
    code: ScribeMeetingType;
    label: string;
    templateId?: string;
};

// Extended type to include custom properties needed for the dropdown
interface ExtendedListItemValue extends ListItemValue<ScribeMeetingType | 'auto-detect'> {
    customContent?: JSX.Element;
    isCustomTemplate?: boolean;
    onClick?: () => boolean;
    templateId?: string;
}

export const MeetingTemplateSelection = ({
    from,
    selectedTemplate,
    setSelectedTemplate,
    updateDefaultSummaryTemplate,
}: {
    from: string;
    selectedTemplate: MeetingTemplateConfig | null;
    setSelectedTemplate: SetValue<MeetingTemplateConfig | null>;
    updateDefaultSummaryTemplate: (template: MeetingTemplateConfig | null, userMetadata: UserMetadata) => Promise<void>;
}) => {
    const track = useExperienceTracking();
    const [isLoading, setIsLoading] = useState(false);

    const [user] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();
    const [templates] = useGlobalCustomTemplates();
    const hasCustomTemplates = !!templates && templates.length > 0;

    // Get available templates based on user permissions
    const includeResearchMeeting = true;
    const includeGeneralSalesMeeting = user.isEnabledForGeneralSalesMeetingType;
    const includeCustomerSuccessCheckIn = user.isEnabledForCustomerSuccessCheckInMeetingType;
    const includeCompanyAllHandsMeeting = user.isEnabledForCompanyAllHandsMeetingType;

    const templateOptions: ExtendedListItemValue[] = Object.values(ScribeMeetingType)
        .filter((meetingType) => {
            if (meetingType === ScribeMeetingType.Custom && !user.isEnabledForCustomSummaryTemplates) {
                return false;
            }
            if (meetingType === ScribeMeetingType.UserResearchInterview && !includeResearchMeeting) {
                return false;
            }
            if (meetingType === ScribeMeetingType.CustomerSuccessCheckIn && !includeCustomerSuccessCheckIn) {
                return false;
            }
            if (meetingType === ScribeMeetingType.GeneralSalesMeeting && !includeGeneralSalesMeeting) {
                return false;
            }
            if (meetingType === ScribeMeetingType.CompanyAllHands && !includeCompanyAllHandsMeeting) {
                return false;
            }
            return true;
        })
        .map((meetingType) => {
            const isCustomTemplate = meetingType === ScribeMeetingType.Custom;
            if (user.isEnabledForCustomSummaryTemplates && isCustomTemplate) {
                const customTemplateOption: ExtendedListItemValue = {
                    isCustomTemplate: true,
                    templateId: templates?.[0]?._id,
                    code: ScribeMeetingType.Custom,
                    label: !hasCustomTemplates ? 'Create custom template' : 'Custom template',
                    isTooltipInteractive: true,
                    tooltip: (
                        <Row>
                            <BodyRegularOnboard>
                                Your custom template, you can edit your custom summary template by clicking{' '}
                                <span
                                    style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                                    onClick={() =>
                                        setGlobalModal(GlobalModal.CreateCustomSummaryTemplate, {
                                            metadata: {
                                                metadataType: GlobalModalMetadataType.CreateCustomSummaryTemplate,
                                                templateId: templates?.[0]?._id,
                                            },
                                        })
                                    }
                                >
                                    here
                                </span>
                            </BodyRegularOnboard>
                        </Row>
                    ),
                    arrowStyle: {
                        border: `1px solid rgb(11, 103, 74)`,
                        backgroundColor: lightTheme.neutrals.white,
                    },
                    tooltipStyle: {
                        maxWidth: '500px',
                        alignItems: 'start',
                        justifyItems: 'start',
                        width: '100%',
                        borderRadius: '15px',
                        padding: '15px',
                        border: `1px solid rgb(11, 103, 74)`,
                        backgroundColor: lightTheme.neutrals.white,
                    },
                };

                // If the user has no templates, modify the label and add onClick handler
                if (!hasCustomTemplates) {
                    // Create a custom React element for the label with green styling
                    customTemplateOption.customLabel = (
                        <Row
                            style={{
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                    textAlign: 'left',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    minHeight: 'auto',
                                    width: '100%',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    fontSize: '1rem',
                                    boxSizing: 'border-box',
                                    fontWeight: 400,
                                    lineHeight: '1.5',
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Create custom template
                            </span>
                        </Row>
                    );

                    customTemplateOption.onClick = () => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Create Custom Template',
                            From: 'meeting_settings',
                        });
                        setGlobalModal(GlobalModal.CreateCustomSummaryTemplate);
                        return true; // Prevent default selection behavior
                    };
                } else {
                    // If the user has templates, just add the edit icon with normal styling
                    customTemplateOption.customLabel = (
                        <Row
                            style={{
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                    textAlign: 'left',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    minHeight: 'auto',
                                    width: '100%',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    fontSize: '1rem',
                                    boxSizing: 'border-box',
                                    fontWeight: 400,
                                    lineHeight: '1.5',
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Custom template
                            </span>
                        </Row>
                    );
                }
                return customTemplateOption;
            }
            return {
                code: meetingType,
                label: ScribeMeetingTypeLabelMap[meetingType],
                isTooltipInteractive: false,
                isCustomTemplate,
            };
        })
        .filter((option) => (!user.isEnabledForCustomSummaryTemplates ? !option.isCustomTemplate : true));

    // Add auto-detect option at the beginning of the dropdown
    templateOptions.unshift({
        code: 'auto-detect',
        label: 'Auto-detect (recommended)',
        isTooltipInteractive: false,
    });

    return (
        <>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <Column style={{ width: 'unset' }}>
                    <Row style={{ flexWrap: 'wrap', width: 'unset' }}>
                        <DropDown
                            style={{
                                width: '250px',
                            }}
                            defaultValue={selectedTemplate?.label || 'Auto-detect (recommended)'}
                            selected={selectedTemplate?.label || 'Auto-detect (recommended)'}
                            buttonProps={{
                                style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                                isLoading,
                            }}
                            handleSelection={async (selectedMeetingType, value) => {
                                try {
                                    setIsLoading(true);

                                    if (typeof value === 'string' || !value) {
                                        return;
                                    }

                                    const extendedValue = value as ExtendedListItemValue;

                                    if (extendedValue.code === ScribeMeetingType.Custom && extendedValue.onClick) {
                                        const preventDefault = extendedValue.onClick();
                                        if (preventDefault) {
                                            return;
                                        }
                                    }

                                    // Handle auto-detect option
                                    if (extendedValue.code === 'auto-detect') {
                                        setSelectedTemplate(null);
                                        await updateDefaultSummaryTemplate(null, user.metadata);

                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Meeting Template Selection',
                                            From: from,
                                            SelectedTemplate: 'auto-detect',
                                        });
                                        return;
                                    }

                                    const newDefaultSummaryTemplate = {
                                        code: extendedValue.code as ScribeMeetingType,
                                        templateId: extendedValue.templateId,
                                        label: ScribeMeetingTypeLabelMap[extendedValue.code],
                                    };

                                    setSelectedTemplate(newDefaultSummaryTemplate);
                                    await updateDefaultSummaryTemplate(newDefaultSummaryTemplate, user.metadata);

                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Meeting Template Selection',
                                        From: from,
                                        SelectedTemplate: extendedValue.code,
                                    });
                                } finally {
                                    setIsLoading(false);
                                }
                            }}
                            title="Meeting Template"
                            values={templateOptions}
                        />
                    </Row>
                </Column>
            )}
        </>
    );
};
