import { useState } from 'react';

import { ScribeMeetingTypeLabelMap } from '@spinach-shared/constants';
import { ClientEventType, ScribeMeetingType } from '@spinach-shared/types';

import { patchAllSeriesSettings, patchUser } from '../../../../apis';
import { ReactComponent as MeetingTemplateIcon } from '../../../../assets/meeting-templates.svg';
import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalCustomTemplates,
    useGlobalModal,
} from '../../../../hooks';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, ButtonSize, HeaderThree } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { UseHoverRowProps } from '../types';
import { MeetingTemplateConfig, MeetingTemplateSelection } from './MeetingTemplateSelection';

// Define styled components for section headers
export const SectionHeader = ({ title, icon }: { title: string; icon: React.ReactNode }): JSX.Element => (
    <Row style={{ alignItems: 'center' }}>
        {icon}
        <HeaderThree style={{ marginLeft: '10px' }}>{title}</HeaderThree>
    </Row>
);

export const SectionSubHeader = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <BodyRegularOnboard style={{ fontWeight: 600 }}>{children}</BodyRegularOnboard>
);

export function MeetingTemplateSettingsSection({ hoverRowProps }: { hoverRowProps: UseHoverRowProps }): JSX.Element {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const { setToastText } = useGlobalAiDashboard();
    const [, setGlobalModal] = useGlobalModal();

    const [templates] = useGlobalCustomTemplates();
    const [isUpdating, setIsUpdating] = useState(false);

    const hasCustomTemplates = !!templates && templates.length > 0;

    // Initialize selected template from user settings

    const defaultTemplateType = user.metadata.defaultSummaryTemplateSettings?.meetingType;

    const [selectedTemplate, setSelectedTemplate] = useState<MeetingTemplateConfig | null>(
        defaultTemplateType
            ? {
                  code: defaultTemplateType,
                  label: ScribeMeetingTypeLabelMap[defaultTemplateType],
              }
            : null
    );

    const updateDefaultSummaryTemplate = async (template: MeetingTemplateConfig | null, userMetadata: any) => {
        if (isUpdating) {
            return;
        }

        try {
            setIsUpdating(true);

            // For auto-detect (null template), we need to clear settings
            if (!template) {
                // Update user settings to clear the template
                const updatedUser = await patchUser({
                    metadata: {
                        defaultSummaryTemplateSettings: {
                            ...userMetadata.defaultSummaryTemplateSettings,
                            // Clear both template type and ID
                            meetingType: null,
                            templateId: null,
                        },
                    },
                });

                // Update all series to clear template overrides
                await patchAllSeriesSettings({
                    clearCustomTemplate: true,
                });

                if (updatedUser?.user) {
                    setUser(updatedUser.user);
                    setToastText('Auto-detect enabled for all meetings');
                }
                await fetch();

                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Update Default Meeting Template',
                    Template: 'auto-detect',
                });

                return;
            }

            // Update user's template settings
            const updatedUser = await patchUser({
                metadata: {
                    defaultSummaryTemplateSettings: {
                        ...userMetadata.defaultSummaryTemplateSettings,
                        // Store the template type and ID
                        meetingType: template.code,
                        templateId: template.code === ScribeMeetingType.Custom ? template.templateId : null,
                    },
                },
            });

            // Update all series to use this template
            await patchAllSeriesSettings({
                customSummaryTemplateType: template.code,
                customSummaryTemplateId:
                    template.code === ScribeMeetingType.Custom ? template.templateId ?? null : null,
            });

            await fetch();

            if (updatedUser?.user) {
                setUser(updatedUser.user);
                setToastText('Template settings updated for all meetings');
            }

            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Update Default Meeting Template',
                Template: template.code,
            });
        } catch (error) {
            setToastText('Failed to update template settings');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <>
            <SectionHeader icon={<MeetingTemplateIcon />} title="Meeting templates" />
            <Spacing factor={1 / 2} />

            <Column>
                <Row vCenter>
                    <BodyRegularOnboard>Select template for all meetings</BodyRegularOnboard>
                    <Spacing factor={1 / 5} horizontal />
                    <MeetingTemplateSelection
                        from="settings_page"
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        updateDefaultSummaryTemplate={updateDefaultSummaryTemplate}
                    />
                </Row>
                <Spacing factor={1 / 2} />
                {user.isEnabledForCustomSummaryTemplates ? (
                    <Row vCenter>
                        <BodyRegularOnboard>Customize your own meeting template</BodyRegularOnboard>
                        <Spacing factor={1 / 5} horizontal />
                        <OutlinedButton
                            size={ButtonSize.Small}
                            title={hasCustomTemplates ? 'Modify Template' : 'Create Template +'}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Create/Modify Custom Template',
                                    From: 'settings_page',
                                });
                                const modalOptions = hasCustomTemplates
                                    ? {
                                          metadata: {
                                              metadataType:
                                                  GlobalModalMetadataType.CreateCustomSummaryTemplate as const,
                                              templateId: templates[0]._id,
                                          },
                                      }
                                    : undefined;
                                setGlobalModal(GlobalModal.CreateCustomSummaryTemplate, modalOptions);
                            }}
                        />
                    </Row>
                ) : null}
            </Column>

            <Spacing factor={1} />
        </>
    );
}
