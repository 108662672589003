import { useState } from 'react';

import { useGlobalAuthedUser, useIsCustomVocabularySettings } from '../../../../hooks';
import { useAtlassianSites } from '../../../../hooks/useAtlassianSites';
import { useConfluenceSpaces } from '../../../../hooks/useConfluenceSpaces';
import { useTicketProjects } from '../../../../hooks/useTicketProjects';
import { HeaderThreeOnboard } from '../../../../styles';
import { Hairline, Row, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import { IntegrationSettingsModalKind, IntegrationSettingsModalMetadata, UseHoverRowProps } from '../types';
import { AutomationIntegrationSection } from './AutomationIntegrationSection';
import { CRMIntegrationsSection } from './CRMIntegrationsSection';
import { CalendarIntegrationSection } from './CalendarIntegrationSection';
import { CommunicationsIntegrationSection } from './CommunicationsIntegrationSection';
import { CustomVocabularySection } from './CustomVocabularySection';
import { GeneralSettingsSection } from './GeneralSettingsSection';
import { KnowledgeBaseIntegrationSection } from './KnowledgeBaseIntegrationSection';
import { McpIntegrationSection } from './McpIntegrationSection';
import { MeetingPlatformIntegrationSection } from './MeetingPlatformIntegrationSection';
import { MeetingTemplateSettingsSection } from './MeetingTemplateSettingsSection';
import { TicketingIntegrationSection } from './TicketingIntegrationSection';

export function useHoverRow(): UseHoverRowProps {
    const [hoveredRow, setHoveredRow] = useState<null | string>(null);

    function getHoverableRowProps(rowId: string) {
        return {
            onMouseEnter: () => {
                setHoveredRow(rowId);
            },
            onMouseLeave: () => {
                setHoveredRow(null);
            },
            style: {
                minHeight: '30px',
            },
        };
    }

    function getVisibleRemoveProps(rowId: string) {
        return {
            isVisible: hoveredRow === rowId,
        };
    }

    return {
        hoveredRow,
        setHoveredRow,
        getHoverableRowProps,
        getVisibleRemoveProps,
    };
}

export function IntegrationsSection({
    setModalKind,
    setModalMetadata,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
}): JSX.Element {
    const hoverRowProps = useHoverRow();
    const [user] = useGlobalAuthedUser();
    useTicketProjects();
    useAtlassianSites();
    useConfluenceSpaces();

    const isCustomVocabularySettings = useIsCustomVocabularySettings();
    const isHubSpotEnabled = user.isHubSpotEnabled;
    const isSalesforceEnabled = user.isSalesforceEnabled;
    const isAttioEnabled = user.isAttioEnabled;
    const isZohoEnabled = user.isZohoEnabled;

    return (
        <>
            <Spacing factor={1 / 2} />

            <Row>
                <HeaderThreeOnboard>Settings</HeaderThreeOnboard>
            </Row>

            <Spacing />

            <ScrollArea sidePadding={0} style={{ width: '100%' }}>
                <GeneralSettingsSection />
                <Hairline />
                <MeetingTemplateSettingsSection hoverRowProps={hoverRowProps} />
                <Hairline />
                <CommunicationsIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                <Hairline />
                <Spacing factor={1 / 2} />
                <CalendarIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                <Hairline />
                <Spacing factor={1 / 2} />
                <TicketingIntegrationSection
                    setModalMetadata={setModalMetadata}
                    setModalKind={setModalKind}
                    hoverRowProps={hoverRowProps}
                />
                <Hairline />
                <Spacing factor={1 / 2} />
                {isCustomVocabularySettings ? (
                    <>
                        <CustomVocabularySection />
                        <Hairline />
                        <Spacing factor={1 / 2} />
                    </>
                ) : undefined}
                <KnowledgeBaseIntegrationSection
                    setModalMetadata={setModalMetadata}
                    setModalKind={setModalKind}
                    hoverRowProps={hoverRowProps}
                />
                <Hairline />
                <Spacing factor={1 / 2} />

                {isHubSpotEnabled || isSalesforceEnabled || isAttioEnabled || isZohoEnabled ? (
                    <>
                        <CRMIntegrationsSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                        <Hairline />
                        <Spacing factor={1 / 2} />
                    </>
                ) : (
                    <></>
                )}

                <MeetingPlatformIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                {user.isMcpIntegrationEnabled ? (
                    <>
                        <Spacing factor={1 / 2} />
                        <McpIntegrationSection hoverRowProps={hoverRowProps} />
                    </>
                ) : undefined}
                <Spacing factor={1 / 2} />
                <AutomationIntegrationSection hoverRowProps={hoverRowProps} />
                <Spacing />
            </ScrollArea>
        </>
    );
}
